import React, { PropsWithChildren } from "react";
import { ConfirmDialog, ConfirmDialogCustomProps } from "./ConfirmDialog";
import { DeleteDialog, DeleteDialogCustomProps } from "./DeleteDialog";

export enum DialogType {
  CONFIRM = "confirm",
  DELETE = "delete",
}

interface CustomProps {
  [DialogType.CONFIRM]: ConfirmDialogCustomProps;
  [DialogType.DELETE]: DeleteDialogCustomProps;
}

type Props<T extends DialogType = DialogType.CONFIRM> = {
  as: DialogType;
  show: boolean;
  customProps?: CustomProps[T];
  onCancel: () => void;
  onSubmit: (dontShowAgain: boolean) => void;
};

export function Dialog<T extends DialogType = DialogType.CONFIRM>({
  as,
  ...props
}: PropsWithChildren<Props<T>>) {
  switch (as) {
    case DialogType.CONFIRM:
      return <ConfirmDialog {...props} />;
    case DialogType.DELETE:
      return <DeleteDialog {...props} />;
    default:
      throw new Error("You must specify a valid DialogType");
  }
}
