const getErrorMessage = (fileType: string, maxSize: string) =>
  `Please select a ${fileType} under ${maxSize}`;

// Generic
export const MAX_FILE_SIZE = 10485760; // 10 MB
export const FILE_ERROR_MESSAGE = getErrorMessage("file", "10 MB");

// Photos
export const MAX_PHOTO_FILE_SIZE = 41943040; // 40 MB
export const PHOTO_ERROR_MESSAGE = getErrorMessage("photo", "40 MB");

// Videos
export const MAX_VIDEO_FILE_SIZE = 4294967296; // 4 GB
export const VIDEO_ERROR_MESSAGE = getErrorMessage("video", "4 GB");

// Video text tracks
export const MAX_VTT_FILE_SIZE = MAX_FILE_SIZE; // 10 MB
export const VTT_SRT_ERROR_MESSAGE = getErrorMessage(".vtt file", "10 MB");
