import React, { PropsWithChildren, useState } from "react";
import Divider from "@mui/material/Divider";
import { Button } from "../../../../Button";
import { ButtonColor } from "../../../../Button/styles";
import { Checkbox } from "../../../../Checkbox";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from "../../../../dialog";
import { Grid } from "../../../../Grid";
import { DialogActionsWithoutPadding } from "./styles";

export type BaseDialogCustomProps = {
  title?: string;
  /**
   * Must be an string which will be used as an identifier to prevent showing it again
   */
  dontShowAgainCheckbox?: string;
};

type Props = {
  show: boolean;
  customProps?: BaseDialogCustomProps;
  cancelButtonText: string;
  submitButtonText: string;
  submitButtonColor?: ButtonColor;
  onClose: () => void;
  onSubmit: (dontShowAgain: boolean) => void;
};

export function BaseDialog({
  children,
  show,
  customProps,
  cancelButtonText,
  submitButtonText,
  submitButtonColor,
  onClose,
  onSubmit,
}: PropsWithChildren<Props>) {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  return (
    <Dialog open={show}>
      <DialogHeader onClose={onClose}>
        {customProps?.title ?? "Confirm Action"}
      </DialogHeader>
      <DialogContent>
        <Grid container item md={12}>
          <Grid item md={12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      {customProps?.dontShowAgainCheckbox && (
        <>
          <DialogActionsWithoutPadding>
            <Grid container>
              <Grid item md={12}>
                <Checkbox
                  label="Don't show again during session"
                  onChange={(event, checked) => setDontShowAgain(checked)}
                />
              </Grid>
            </Grid>
          </DialogActionsWithoutPadding>
          <Divider />
        </>
      )}
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Button
              type="button"
              text={cancelButtonText}
              align="center"
              color="utility"
              onClick={() => {
                onClose();
                setDontShowAgain(false);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Button
              type="button"
              text={submitButtonText}
              align="center"
              color={submitButtonColor}
              onClick={() => {
                onSubmit(dontShowAgain);
                setDontShowAgain(false);
              }}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
