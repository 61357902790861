import { styled } from "@mui/material/styles";
import DialogMui, { DialogProps } from "@mui/material/Dialog";

type StyledDialogProps = DialogProps;

export const Dialog = styled(DialogMui)<StyledDialogProps>`
  .MuiDialog-paper {
    border-radius: ${({ theme, fullScreen }) =>
      fullScreen ? 0 : theme.kinetic.borderRadius.lg};
    overflow-y: hidden;
    max-height: initial;
  }
`;
