import { styled } from "@mui/material/styles";
import CheckboxMui from "@mui/material/Checkbox";
import FormControlLabelMui from "@mui/material/FormControlLabel";
import { Body2 } from "../BSPBody2";

export const FormControlLabel = styled(FormControlLabelMui)`
  ${Body2};
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const Checkbox = styled(CheckboxMui)`
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};

  // let user select the checkbox by clicking on label, but without selecting label text
  & + span.MuiTypography-root {
    user-select: none;
  }

  &.Mui-checked {
    color: ${({ theme }) => theme.kinetic.v2.palette.rgb.blue};
    border: ${({ theme }) => theme.kinetic.palette.rgb.gray400};
  }

  &:disabled,
  &.Mui-disabled,
  &.Checkbox--Disabled {
    color: ${({ theme }) => theme.kinetic.palette.rgb.gray400};
  }

  &:hover:not(.Mui-disabled),
  &.Checkbox--hover:not(.Mui-disabled) {
    background-color: ${({ theme }) => theme.kinetic.palette.rgb.gray100};
  }

  &:focus:not(.Mui-disabled),
  &.Checkbox--focus:not(.Mui-disabled),
  &.Mui-focusVisible:not(.Mui-disabled) {
    background-color: ${({ theme }) => theme.kinetic.palette.rgb.blue100};
  }
`;
