import React, { ComponentPropsWithoutRef, useId } from "react";
import { Root, Spinner, HiddenSvg, ProgressColorProp } from "./styles";
import { SxProps } from "@mui/system";

interface Props extends ComponentPropsWithoutRef<"div"> {
  size?: "xs" | "sm" | "lg";
  color?: ProgressColorProp;
  sx?: SxProps;
}

const measurements = {
  xs: {
    diameter: 16,
    thickness: 3,
  },
  sm: {
    diameter: 24,
    thickness: 4,
  },
  lg: {
    diameter: 48,
    thickness: 6,
  },
};

function moveTo(x: number, y: number) {
  return `M${x},${y}`;
}

function arc(radius: number, x: number, y: number) {
  return `A${radius},${radius} 0,1,1 ${x},${y}`;
}

function getDonutPath(diameter: number, thickness: number) {
  const radius = diameter / 2;
  const halfRadius = radius / 2;

  const donutPath = [
    moveTo(0, radius),
    arc(radius, diameter, radius),
    arc(radius, 0, radius),
    "z",
    moveTo(thickness, radius),
    arc(halfRadius, diameter - thickness, radius),
    arc(halfRadius, thickness, radius),
    "z",
  ].join(" ");

  return donutPath;
}

export function CircularProgress({
  size = "lg",
  color = "blue",
  ...props
}: Props) {
  const { diameter, thickness } = measurements[size];
  const donutPath = getDonutPath(diameter, thickness);
  const donutPathId = useId();

  return (
    <Root
      role="progressbar"
      // @types/react is broken for aria-valuenow
      aria-valuenow={"indeterminate" as unknown as number}
      $diameter={diameter}
      {...props}
    >
      <HiddenSvg
        xmlns="http://www.w3.org/2000/svg"
        width={diameter}
        height={diameter}
      >
        <defs>
          <clipPath id={donutPathId}>
            <path d={donutPath} clipRule="evenodd" />
          </clipPath>
        </defs>
      </HiddenSvg>
      <Spinner $donutPathId={donutPathId} $diameter={diameter} $color={color} />
    </Root>
  );
}
