import { equals } from "ramda";
import { nanoid } from "nanoid";

export type PwaIdentificationType = {
  email: string | null;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  createdAt: string | null;
  isCoach: boolean | null;
  userId: string | null;
  externalId: string | null;
  selectedLanguage: string | null | undefined;
  totalPoints: number | null;
  company: string | null;
  companyDisplayName: string | null;
  companyId: string | null;
  companyProfileId: string | null;
  companyExternalId: string | null;
  isSuperAdmin?: boolean;
  excludeFromAnalytics?: boolean;
  pathname?: string;
  flags?: Record<string, string>;
  authOverrideOriginalExternalId: string | null;
  homeHeroSectionEnabled: boolean;
};

export type AdminIdentificationType = {
  userId: string;
  companyProfileId: string;
  externalId: string;
  name: string | null;
  email: string | null;
  phone: string | null;
  createdAt: string;
  isCoach: boolean;
  isSuperAdmin: boolean;
  companyId: string;
  company: string | null;
  companyExternalId: string | null;
  roles: string[];
  flags?: Record<string, string>;
};

const DEBUG = false;
const DEFAULT_IDENTIFY_PARAMS = {
  email: null,
  name: null,
  firstName: null,
  lastName: null,
  phone: null,
  createdAt: null,
  isCoach: false,
  userId: null,
  externalId: null,
  selectedLanguage: null,
  totalPoints: 0,
  company: null,
  companyDisplayName: null,
  companyId: null,
  companyProfileId: null,
  companyExternalId: null,
  isSuperAdmin: false,
  excludeFromAnalytics: false,
  pathname: undefined,
  flags: {},
  authOverrideOriginalExternalId: null,
  homeHeroSectionEnabled: false,
};

const SESSION_STARTED = {
  name: "Session Started",
  properties: (p: { sessionId: string }) => ({
    ...p,
    internalEventId: "PWA-1",
  }),
};

const SESSION_ENDED = {
  name: "Session Ended",
  properties: (p: { sessionId: string | null }) => ({
    ...p,
    internalEventId: "PWA-24",
  }),
};

// Not a `track` event, but defines the shape of super properties we expect
const IDENTIFY = {
  properties: (p: PwaIdentificationType | AdminIdentificationType) => p,
};

/**
 * Beware: values are mutated over the course of a session.
 */
export let GLOBALS: {
  prevIdentifyParams: PwaIdentificationType | AdminIdentificationType;
  hasIdentifiedSuccessfully: boolean;
  sessionId: string | null;
  unloadListener: EventListener | null;
} = {
  prevIdentifyParams: DEFAULT_IDENTIFY_PARAMS,
  sessionId: null,
  unloadListener: null,
  hasIdentifiedSuccessfully: false,
};

/**
 * softReset used for auth override to retain traits of original user
 */
export const softReset = () => {
  _reset();
};

/**
 * hardReset used for when user logs out, to wipe traits
 */
export const hardReset = () => {
  GLOBALS = {
    prevIdentifyParams: DEFAULT_IDENTIFY_PARAMS,
    sessionId: null,
    unloadListener: null,
    hasIdentifiedSuccessfully: false,
  };
  softReset();
  restartIntercomToClearCookiesAndRemoveUserSession();
};

/**
 *
 */
function restartIntercomToClearCookiesAndRemoveUserSession() {
  window.Intercom?.("shutdown");

  window.Intercom?.("boot", {
    // TODO: Improve types here
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    app_id: (
      window.analytics.queue?.plugins.find(
        // TODO: Improve types here
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (plugin) => plugin.name === "Intercom"
      ) as any
    ).settings.appId,
  });
}

/**
 *
 * @param name
 * @param properties
 * @returns
 */
export const page = (name: string, properties: Record<string, unknown>) => {
  return _page(name, { ...properties, sessionId: GLOBALS.sessionId });
};

/**
 *
 * @param data
 */
export const identify = async (
  data: PwaIdentificationType | AdminIdentificationType
) => {
  if (data && data.userId) {
    // Just to call to Alias the first time and Identify to an Anonymous user.
    if (!GLOBALS.hasIdentifiedSuccessfully) {
      GLOBALS.hasIdentifiedSuccessfully = true;

      IDENTIFY.properties(data);

      await new Promise((resolve) =>
        _alias(data.userId, undefined, undefined, resolve)
      );
    }

    if (!equals(data, GLOBALS.prevIdentifyParams)) {
      _identify(data.userId, data);
      if (data.companyId) {
        _group(data.companyId, {
          name: data.company,
          externalId: data.companyExternalId,
          // DisplayName is only available in PWA.
          displayName:
            (data as PwaIdentificationType)?.companyDisplayName || null,
        });
      }
    }
  }
  GLOBALS.prevIdentifyParams = data;
};

/**
 *
 * @returns
 */
export const getIdentifyInfo = () => {
  if (!GLOBALS.sessionId) {
    handleSessions();
  }

  return GLOBALS;
};

/**
 *
 * @param name
 * @param properties
 * @returns
 */
export const track = (name: string, properties: Record<string, unknown>) => {
  if (!GLOBALS.sessionId) {
    handleSessions();
  }

  return _track(
    name,
    {
      ...properties,
      sessionId: GLOBALS.sessionId,
    },
    { context: { traits: { ...GLOBALS.prevIdentifyParams } } }
  );
};

/**
 *
 * @param companyId
 * @param properties
 * @returns
 */
export const group = (
  companyId: string,
  properties: Record<string, unknown>
): void => {
  return _group(companyId, { ...properties });
};

/**
 * Returns a new function that calls the passed analytics.js function with the
 * given name if analytics has loaded on the page. This prevents runtime errors
 * as a result of an ad blocker or other failure causing external scripts to load
 *
 * @param name
 * @returns
 */
const call =
  (name: string) =>
  (...args: any[]) => {
    if (DEBUG) {
      console.log(name, JSON.stringify(args));
    }

    if ((window as any).analytics) {
      if (typeof (window as any).analytics[name] !== "function") {
        console.warn(
          [
            `analytics.js does not have a function named '${name}'.`,
            "  This could mean you're trying to call a function that doesn't exist",
            "    at all or something has gone awry in analytics.js itself.",
            `  Check ~/lib/segment.ts for a line containing "call('${name}')" and`,
            `    see if '${name}' exists in the analytics.js docs:`,
            `  https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#${name}`,
          ].join("\n")
        );
      }

      try {
        (window as any).analytics[name](...args);
      } catch (e) {
        console.error(e);
      }
    }
  };

/**
 * It calls segment events with the existing session registered on GLOBAL
 * or it creates a new one calling to handleSessions() function.
 *
 * @param fn
 * @returns
 */
const withSession =
  (fn: (...args: any[]) => void) =>
  (...args: any[]) => {
    if (!GLOBALS.sessionId) {
      handleSessions();
    }
    fn(...args);
  };

/**
 * It created a SessioniId and sends tracks to regiter when a Session is Started or Ended.
 */
const handleSessions = () => {
  GLOBALS.sessionId = nanoid();

  call("track")(
    SESSION_STARTED.name,
    SESSION_STARTED.properties({
      sessionId: GLOBALS.sessionId,
    })
  );

  if (!GLOBALS.unloadListener) {
    const handleUnload = () => {
      if (GLOBALS.unloadListener) {
        window.removeEventListener("unload", GLOBALS.unloadListener);
      }
      call("track")(
        SESSION_ENDED.name,
        SESSION_ENDED.properties({
          sessionId: GLOBALS.sessionId,
        })
      );
      GLOBALS.unloadListener = null;
      GLOBALS.sessionId = null;
    };

    window.addEventListener("unload", handleUnload);

    GLOBALS.unloadListener = handleUnload;
  }
};

/**
 * Real call to the Segment event: "page" with a SessionId.
 */
const _page = withSession(call("page"));

/**
 * Real call to the Segment event: "group" with a SessionId.
 */
const _group = withSession(call("group"));

/**
 * Real call to the Segment event: "track" with a SessionId.
 */
const _track = withSession(call("track"));

/**
 * Real call to the Segment event: "identify" with a SessionId.
 */
const _identify = withSession(call("identify"));

/**
 * Real call to the Segment event: "alias".
 *
 * Identify to an Anonymous user and give to him a profileId.
 * https://segment.com/docs/connections/spec/alias/
 */
const _alias = call("alias");

/**
 * Real call to the Segment event: "reset".
 */
const _reset = call("reset");
