import {
  Checkbox as StyledCheckbox,
  FormControlLabel as StyledFormControlLabel,
} from "./styles";
import React, { ReactNode } from "react";
import { CheckboxProps as CheckboxPropsBase } from "@mui/material/Checkbox";

export type CheckboxProps = { label?: ReactNode } & CheckboxPropsBase;

export function Checkbox({ label, ...props }: CheckboxProps) {
  if (!label) {
    return <StyledCheckbox {...props} />;
  }

  return (
    <StyledFormControlLabel
      label={label}
      control={<StyledCheckbox {...props} />}
    />
  );
}
