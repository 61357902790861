import { userAgent } from "./userAgent";

/**
 * Get user traits for Profile.flags' `IdentifyArgs`
 *
 * @param appName 'pwa' or 'admin'
 * @returns `IdentifyArgs`
 */
export const getTraits = (appName?: "pwa" | "admin") => ({
  appName: appName ? `bigspring-${appName}` : null,
  appVersion:
    process.env.NEXT_PUBLIC_VERSION ||
    process.env.VERSION ||
    process.env.GIT_TAG_NAME ||
    "local",
  browserName: userAgent.browser.name,
  browserVersion: userAgent.browser.version,
  osName: userAgent.os.name,
  osVersion: userAgent.os.version,
  platform: "web",
  platformType: userAgent.platform.type,
  platformVendor: userAgent.platform.vendor,
  platformModel: userAgent.platform.model,
});
