import { DialogActionsProps } from "@mui/material/DialogActions";
import { DialogActions as StyledDialogActions } from "./styles";
import React from "react";

export type Props = DialogActionsProps &
  React.PropsWithChildren<{
    color?: "primary" | "positive" | "negative" | "warning";
  }>;

export function DialogActions({
  color,
  disableSpacing = true,
  ...rest
}: Props) {
  return (
    <StyledDialogActions
      $color={color}
      disableSpacing={disableSpacing}
      {...rest}
    />
  );
}
