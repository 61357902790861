export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait = 300
) {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: T) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback.apply(this, args), wait);
  };
}
