import { css, styled } from "@mui/material/styles";

export const Body2 = css`
  font-family: "Roboto", var(--bigspring-primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
`;

export const BSPBody2 = styled("span")`
  ${Body2}
`;
