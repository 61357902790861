import { css, styled } from "@mui/material/styles";

export const Body1 = css`
  font-family: "Roboto", var(--bigspring-primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0025em;
`;

export const BSPBody1 = styled("span")`
  ${Body1};
`;
