import React, { PropsWithChildren } from "react";
import {
  DialogTitleItem,
  DialogTitle,
  DialogSubtitle,
  DialogCloseButton,
  Wrapper,
  LeftSpaceHolder,
} from "./styles";
import { Grid } from "../../Grid";
import { CloseIcon } from "../../../../../icons";

export type Props = {
  title?: string;
  subtitle?: string;
  id?: string;
  isCenter?: boolean;
  onClose?: (...args: any[]) => any;
};

export function DialogHeader({
  children,
  title,
  subtitle,
  id,
  isCenter = false,
  onClose,
  ...rest
}: PropsWithChildren<Props>) {
  const hasCloseButton = !!onClose;

  return (
    <Wrapper container alignItems="center" {...rest}>
      {hasCloseButton && <LeftSpaceHolder />}
      <DialogTitleItem
        item
        $isCenter={isCenter}
        $hasCloseButton={hasCloseButton}
      >
        <DialogTitle id={id}>{children || title}</DialogTitle>
        {!!subtitle && <DialogSubtitle>{subtitle}</DialogSubtitle>}
      </DialogTitleItem>
      {hasCloseButton && (
        <Grid item>
          <DialogCloseButton onClick={onClose}>
            <CloseIcon $large />
          </DialogCloseButton>
        </Grid>
      )}
    </Wrapper>
  );
}

export default DialogHeader;
