import React, { PropsWithChildren } from "react";
import { BSPBody1 } from "../../../../BSPBody1";
import { BaseDialogCustomProps, BaseDialog } from "../BaseDialog";
import { DeleteWarning } from "./styles";

export type DeleteDialogCustomProps = BaseDialogCustomProps & {
  hideCantBeUndoneWarning?: boolean;
};

type Props = {
  show: boolean;
  customProps?: DeleteDialogCustomProps;
  onCancel: () => void;
  onSubmit: (dontShowAgain: boolean) => void;
};

export function DeleteDialog({
  show,
  customProps,
  onCancel,
  onSubmit,
  children,
}: PropsWithChildren<Props>) {
  return (
    <BaseDialog
      show={show}
      customProps={customProps}
      cancelButtonText="Cancel"
      submitButtonText="Delete"
      submitButtonColor="negative"
      onClose={onCancel}
      onSubmit={onSubmit}
    >
      {typeof children === "string" ? (
        <BSPBody1 dangerouslySetInnerHTML={{ __html: children }} />
      ) : (
        children
      )}
      {!customProps?.hideCantBeUndoneWarning && (
        <DeleteWarning>This action can&apos;t be undone.</DeleteWarning>
      )}
    </BaseDialog>
  );
}
