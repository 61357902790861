import Bowser from "bowser";
import { isBrowser } from "./is-browser";

/**
 * IMPORTANT: We can't trust this anymore to detect whether is mobile or not.
 * window.navigator.userAgent.platform is deprecated and some browsers don't support it anymore.
 * Use ismobilejs library instead
 */

type Navigator = globalThis.Navigator & { msMaxTouchPoints: number };

export const userAgent: Bowser.Parser.ParsedResult = isBrowser()
  ? // eslint-disable-next-line import/no-named-as-default-member
    Bowser.parse(window.navigator.userAgent)
  : { browser: {}, os: {}, platform: {}, engine: {} };

const checkOsName = (os: string) => userAgent.os.name === os;

const checkBrowserName = (browser: string) =>
  userAgent.browser.name === browser;

export const isMacOS = checkOsName("macOS");

export const isIos = checkOsName("iOS");

export const isAndroid = checkOsName("Android");

/**
 * @deprecated Use ismobilejs library instead
 */
export const isMobile = userAgent.platform.type === "mobile";

export const isSafari = checkBrowserName("Safari");

export const isTouchDevice =
  isBrowser() &&
  ("ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as Navigator).msMaxTouchPoints > 0 ||
    isMobile);

export const isFrame = isBrowser() ? window !== window.parent : false;
