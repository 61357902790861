import {
  Button as StyledButton,
  ButtonColor,
  Left,
  LeftBody,
  ButtonLeftProps,
} from "./styles";
import React, { ReactNode } from "react";
import { BSPBody1 } from "../BSPBody1";
import { BSPSubtext } from "../BSPSubtext";
import { BSPButtonLabel } from "../typographies";
import { CircularProgress } from "../CircularProgress";
import { ButtonProps as ButtonPropsBase } from "@mui/material/Button";

type OwnProps = {
  id?: string;
  tabIndex?: number;
  text?: ReactNode;
  subtext?: ReactNode;
  color?: ButtonColor;
  loading?: boolean;
  selected?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  icon?: ReactNode;
  align?: ButtonLeftProps["$align"];
};
export type ButtonProps = Omit<ButtonPropsBase, "color"> &
  React.PropsWithChildren<OwnProps>;

export function Button({
  loading = false,
  disabled = false,
  color = "primary",
  align = "left",
  icon,
  ...props
}: ButtonProps) {
  const componentProps = {
    tabIndex: disabled ? -1 : props.tabIndex ? props.tabIndex : 0,
    $loading: loading,
    disabled,
    $color: color,
    ...props,
    startIcon: null, // force null this because we need use our own StartIcon.
    endIcon: loading ? null : props.endIcon ? props.endIcon : null,
    text: icon ? null : props.text,
    $icon: !!icon,
  };

  return (
    <StyledButton {...componentProps}>
      {loading ? (
        <CircularProgress
          size="sm"
          color={color === "primary" ? "white" : "blue"}
        />
      ) : (
        props.children || (
          <Left $align={align} $hasStartIcon={!!props.startIcon}>
            {props.startIcon}

            <LeftBody>
              {props.text ? (
                props.subtext ? (
                  <BSPBody1>{props.text}</BSPBody1>
                ) : (
                  <BSPButtonLabel>{props.text}</BSPButtonLabel>
                )
              ) : (
                icon && icon
              )}
              {props.subtext && <BSPSubtext>{props.subtext}</BSPSubtext>}
            </LeftBody>
          </Left>
        )
      )}
    </StyledButton>
  );
}
