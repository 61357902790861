import { css, styled, Theme } from "@mui/material/styles";
import ButtonMui, { ButtonProps as ButtonMuiProps } from "@mui/material/Button";
import { withTransientProps } from "../../../lib/emotion";

// TODO: Evaluate to create an admin button if the new colors are not
// going to be used in PWA
export type ButtonColor =
  | "primary"
  | "secondary"
  | "positive"
  | "negative"
  | "warning"
  | "tertiary"
  | "utility"
  | "admin-navigation"
  | "lightblue"
  | "social"
  | "dark"; // TODO: Evaluate to start a new theme

interface ButtonOwnProps {
  $color?: ButtonColor;
  $icon?: boolean;
  selected?: boolean;
  $loading?: boolean;
  $align?: "left" | "center" | "right";
}

type ButtonProps = Omit<ButtonMuiProps, "color"> & ButtonOwnProps;

export type ButtonLeftProps = {
  $align: "left" | "center" | "right";
  $hasStartIcon: boolean;
};
export const Left = styled("div", withTransientProps)<ButtonLeftProps>`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${({ $hasStartIcon }) => ($hasStartIcon ? 10 : 0)}px;
  }

  ${({ $align }) => {
    switch ($align) {
      case "center":
        return css`
          margin: 0 auto;
        `;
      case "right":
        return css`
          justify-content: flex-end;
          width: 100%;
        `;
      default:
        return css`
          justify-content: flex-start;
          width: 100%;
        `;
    }
  }};
`;

export const LeftBody = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const getColor = ({
  $color,
  ...props
}: ButtonProps & {
  theme: Theme;
}) => {
  switch ($color) {
    case "primary":
      return ColorPrimary(props);
    case "secondary":
      return ColorSecondary(props);
    case "positive":
      return ColorPositive(props);
    case "negative":
      return ColorNegative(props);
    case "warning":
      return ColorWarning(props);
    case "tertiary":
      return ColorTertiary(props);
    case "utility":
      return ColorUtility(props);
    case "admin-navigation":
      return ColorAdminNavigation(props);
    case "lightblue":
      return LightBlue(props);
    case "social":
      return ColorSocial(props);
    case "dark":
      return ColorDark(props);
    default:
      return ColorPrimary(props);
  }
};

export const Button = styled(ButtonMui, withTransientProps)<ButtonProps>`
  text-transform: none;
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.md};
  padding: ${({ $loading, theme }) =>
    `${$loading ? "10px " : ""}${theme.kinetic.paddings[12]}`};
  width: 100%;
  display: flex;
  justify-content: ${({ $loading, $align }) =>
    $loading
      ? "center"
      : $align === "center"
      ? "center"
      : $align === "right"
      ? "flex-end"
      : "flex-start"};
  min-width: auto;

  &:hover {
    cursor: pointer;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }

  ${(props) => getColor(props)};

  &:disabled,
  &.Button--disabled {
    background-color: ${({ theme }) => theme.kinetic.palette.rgb.gray100};
    color: ${({ theme }) => theme.kinetic.palette.rgb.gray400};
    border: ${({ theme }) => `1px solid ${theme.kinetic.palette.rgb.gray400}`};
    span {
      color: ${({ theme }) => theme.kinetic.palette.rgb.gray400};
    }
    svg {
      fill: ${({ theme }) => theme.kinetic.palette.rgb.gray400};
    }
  }
`;

const ColorPrimary = ({
  theme,
}: ButtonProps & {
  theme: Theme;
}) => css`
  background-color: ${theme.kinetic.v2.palette.rgb.blue};
  border: ${`1px solid ${theme.kinetic.v2.palette.rgb.blue}`};
  color: ${theme.kinetic.v2.palette.rgb.white};
  span {
    color: ${theme.kinetic.v2.palette.rgb.white};
  }
  svg {
    fill: ${theme.kinetic.v2.palette.rgb.white};
  }
  :hover,
  &.Button--hover {
    border: ${`1px solid ${theme.kinetic.v2.palette.rgb.blueDark}`};
    background-color: ${theme.kinetic.v2.palette.rgb.blueDark};
    box-shadow: ${`0px 2px 2px ${theme.kinetic.v2.palette.rgba.grayTextDark(
      0.16
    )}, 0px 2px 8px ${theme.kinetic.v2.palette.rgba.grayTextDark(0.24)}`};
  }
  :focus-visible,
  :active,
  &.Button--focus {
    border: ${`1px solid ${theme.kinetic.v2.palette.rgb.blueDark}`};
    background-color: ${theme.kinetic.v2.palette.rgb.blueDark};
  }
`;

const ColorSecondary = ({
  theme,
}: ButtonProps & {
  theme: Theme;
}) => css`
  background-color: ${theme.kinetic.palette.rgb.white};
  border: ${`1px solid ${theme.kinetic.v2.palette.rgb.blue}`};
  color: ${theme.kinetic.v2.palette.rgb.blue};
  span {
    color: ${theme.kinetic.v2.palette.rgb.blue};
  }
  svg {
    fill: ${theme.kinetic.v2.palette.rgb.blue};
  }
  :hover,
  &.Button--hover {
    color: ${theme.kinetic.palette.rgb.blue300};
    background-color: ${theme.kinetic.palette.rgb.white};
    box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.25);
    span {
      color: ${theme.kinetic.palette.rgb.blue300};
    }
    svg {
      fill: ${theme.kinetic.palette.rgb.blue300};
    }
  }
  :focus-visible,
  :active,
  &.Button--focus {
    color: ${theme.kinetic.palette.rgb.blue700};
    background-color: ${theme.kinetic.palette.rgb.blue100};
    span {
      color: ${theme.kinetic.palette.rgb.blue700};
    }
    svg {
      fill: ${theme.kinetic.palette.rgb.blue700};
    }
  }
`;

const ColorPositive = ({
  theme,
}: ButtonProps & {
  theme: Theme;
}) => css`
  background-color: ${theme.kinetic.v2.palette.rgb.positive};
  border: none;
  color: ${theme.kinetic.v2.palette.rgb.white};
  span {
    color: ${theme.kinetic.v2.palette.rgb.white};
  }
  svg {
    fill: ${theme.kinetic.v2.palette.rgb.white};
  }
  :hover,
  &.Button--hover {
    background-color: ${theme.kinetic.v2.palette.rgb.positiveDark};
    box-shadow: ${`0px 2px 2px ${theme.kinetic.v2.palette.rgba.grayTextDark(
      0.16
    )}, 0px 2px 8px ${theme.kinetic.v2.palette.rgba.grayTextDark(0.24)}`};
  }
  :focus-visible,
  :active,
  &.Button--focus {
    background-color: ${theme.kinetic.v2.palette.rgb.positiveDarker};
  }
`;

const ColorNegative = ({
  theme,
}: ButtonProps & {
  theme: Theme;
}) => css`
  background-color: ${theme.kinetic.v2.palette.rgb.negative};
  border: none;
  color: ${theme.kinetic.v2.palette.rgb.white};
  span {
    color: ${theme.kinetic.v2.palette.rgb.white};
  }
  svg {
    fill: ${theme.kinetic.v2.palette.rgb.white};
  }
  :hover,
  &.Button--hover {
    background-color: ${theme.kinetic.v2.palette.rgb.negativeDark};
    box-shadow: ${`0px 2px 2px ${theme.kinetic.v2.palette.rgba.grayTextDark(
      0.16
    )}, 0px 2px 8px ${theme.kinetic.v2.palette.rgba.grayTextDark(0.24)}`};
  }
  :focus-visible,
  :active,
  &.Button--focus {
    background-color: ${theme.kinetic.v2.palette.rgb.negativeDarker};
  }
`;

const ColorWarning = ({
  theme,
}: ButtonProps & {
  theme: Theme;
}) => css`
  background-color: ${theme.kinetic.v2.palette.rgb.warning};
  border: none;
  color: ${theme.kinetic.v2.palette.rgb.white};
  span {
    color: ${theme.kinetic.v2.palette.rgb.white};
  }
  svg {
    fill: ${theme.kinetic.v2.palette.rgb.white};
  }
  :hover,
  &.Button--hover {
    background-color: ${theme.kinetic.v2.palette.rgb.warningDark};
    box-shadow: ${`0px 2px 2px ${theme.kinetic.v2.palette.rgba.grayTextDark(
      0.16
    )}, 0px 2px 8px ${theme.kinetic.v2.palette.rgba.grayTextDark(0.24)}`};
  }
  :focus-visible,
  :active,
  &.Button--focus {
    background-color: ${theme.kinetic.v2.palette.rgb.warningDarker};
  }
`;

const ColorUtility = ({
  theme,
}: ButtonProps & {
  theme: Theme;
}) => css`
  background-color: ${theme.kinetic.palette.rgb.white};
  border: ${`1px solid ${theme.kinetic.palette.rgb.gray200}`};
  color: ${theme.kinetic.palette.rgb.gray800};
  span {
    color: ${theme.kinetic.palette.rgb.gray800};
  }
  svg {
    fill: ${theme.kinetic.palette.rgb.gray800};
  }
  :hover,
  &.Button--hover {
    background-color: ${theme.kinetic.palette.rgb.white};
    box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.25);
    span {
      color: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
    }
    svg {
      fill: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
    }
  }
  :focus-visible,
  :active,
  &.Button--focus {
    background-color: ${theme.kinetic.palette.rgb.gray100};
    border: ${`1px solid ${theme.kinetic.palette.rgb.gray400}`};
    span {
      color: ${theme.kinetic.palette.rgb.gray800};
    }
    svg {
      fill: ${theme.kinetic.palette.rgb.gray800};
    }
  }
`;

/**
 * Foundry table buttons
 */
const ColorTertiary = ({
  theme,
  selected,
}: ButtonProps & {
  theme: Theme;
}) => css`
  background-color: transparent !important;
  color: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
  span {
    color: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
  }
  svg {
    fill: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
  }
  :hover,
  &.Button--hover {
    span {
      color: ${theme.kinetic.v2.palette.rgb.grayTextLight};
    }
    svg {
      fill: ${theme.kinetic.v2.palette.rgb.grayTextLight};
    }
  }

  :focus-visible,
  :active,
  &.Button--focus {
    span {
      color: ${theme.kinetic.v2.palette.rgb.grayTextLight};
    }
    svg {
      fill: ${theme.kinetic.v2.palette.rgb.grayTextLight};
    }
  }

  :disabled,
  &.Button--disabled {
    color: ${theme.kinetic.palette.rgb.gray400};
    border: none !important;
    span {
      color: ${theme.kinetic.palette.rgb.gray400};
    }
    svg {
      fill: ${theme.kinetic.palette.rgb.gray400};
    }
  }

  ${selected &&
  css`
    span {
      color: ${theme.kinetic.v2.palette.rgb.grayTextLight};
    }
    svg {
      fill: ${theme.kinetic.v2.palette.rgb.grayTextLight};
    }
  `};
`;

const ColorAdminNavigation = ({
  theme,
  selected,
}: ButtonProps & {
  theme: Theme;
}) => css`
  background-color: ${theme.radix.colors.white};
  border: ${`1px solid ${theme.radix.colors.white}`};
  border-radius: 0;
  color: ${theme.radix.colors.gray.gray1};
  span {
    color: ${theme.radix.colors.gray.gray1};
  }
  svg {
    fill: ${theme.radix.colors.gray.gray1};
  }
  :hover,
  &.Button--hover {
    background-color: ${theme.radix.colors.blue.blue2};
    border: ${`1px solid ${theme.radix.colors.blue.blue2}`};
    box-shadow: 0px 2px 8px rgba(32, 32, 32, 0.25);
    span {
      color: ${theme.radix.colors.white};
    }
    svg {
      fill: ${theme.radix.colors.white};
    }
  }

  :focus-visible,
  :active,
  &.Button--focus {
    background-color: ${theme.radix.colors.blue.blue2};
    border: ${`1px solid ${theme.radix.colors.blue.blue2}`};
    box-shadow: none;
    span {
      color: ${theme.radix.colors.white};
    }
    svg {
      fill: ${theme.radix.colors.white};
    }
  }

  ${selected &&
  css`
    background-color: ${theme.radix.colors.blue.blue2} !important;
    border: ${`1px solid ${theme.radix.colors.blue.blue2}`};
    span {
      color: ${theme.radix.colors.white};
    }
    svg {
      fill: ${theme.radix.colors.white};
    }
  `};
`;

const LightBlue = ({
  theme,
}: ButtonProps & {
  theme: Theme;
}) => css`
  background-color: ${theme.radix.colors.blue.blue1};
  border: ${`1px solid ${theme.radix.colors.blue.blue1}`};
  color: ${theme.radix.colors.blue.blue2};
  span {
    color: ${theme.radix.colors.blue.blue2};
  }
  svg {
    fill: ${theme.radix.colors.blue.blue2};
  }
  :hover,
  &.Button--hover {
    border: ${`1px solid ${theme.radix.colors.blue.blue1}`};
    background-color: ${theme.radix.colors.blue.blue1};
    box-shadow: none;
  }
  :focus-visible,
  :active,
  &.Button--focus {
    border: ${`1px solid ${theme.radix.colors.blue.blue1}`};
    background-color: ${theme.radix.colors.blue.blue1};
  }
`;

const colorSocialActive = ({ theme }: { theme: Theme }) => css`
  color: ${theme.kinetic.v2.palette.rgb.grayTextDark};
  span {
    color: ${theme.kinetic.v2.palette.rgb.grayTextDark};
  }
  svg {
    fill: ${theme.kinetic.v2.palette.rgb.grayTextDark};
  }
`;

const ColorSocial = ({
  theme,
  selected,
}: ButtonProps & {
  theme: Theme;
}) => css`
  align-items: center;
  width: auto;
  padding: ${theme.kinetic.paddings[12]} 0;
  background-color: transparent !important;
  border-radius: ${theme.kinetic.borderRadius.lg};
  color: ${theme.kinetic.v2.palette.rgb.grayTextLighter};

  span {
    color: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
  }
  svg {
    fill: ${theme.kinetic.v2.palette.rgb.grayTextLighter};
  }
  :hover,
  &.Button--hover {
    span {
      color: ${theme.kinetic.v2.palette.rgb.grayTextLight};
    }
    svg {
      fill: ${theme.kinetic.v2.palette.rgb.grayTextLight};
    }
  }
  :focus-visible,
  &.Button--focus {
    box-shadow: 0 0 0 2px ${theme.kinetic.v2.palette.rgb.fluoro};
    ${colorSocialActive({ theme })};
  }
  :disabled,
  &.Button--disabled {
    color: ${theme.kinetic.v2.palette.rgb.grayUiLighter};
    span {
      color: ${theme.kinetic.v2.palette.rgb.grayUiLighter};
    }
    svg {
      fill: ${theme.kinetic.v2.palette.rgb.grayUiLighter};
    }
  }
  ${selected && colorSocialActive({ theme })};
`;

const ColorDark = ({
  theme,
}: ButtonProps & {
  theme: Theme;
}) => css`
  align-items: center;
  width: auto;
  padding: ${theme.kinetic.paddings[10]} ${theme.kinetic.paddings[16]};
  background-color: #494947;
  border: 1px solid transparent;
  border-radius: ${theme.kinetic.borderRadius.md};
  color: ${theme.kinetic.v2.palette.rgb.white};

  span {
    color: ${theme.kinetic.v2.palette.rgb.white};
  }
  svg {
    fill: ${theme.kinetic.v2.palette.rgb.white};
  }
  :hover,
  &.Button--hover {
    background-color: #494947;
  }
  :disabled,
  &.Button--disabled {
    background-color: ${theme.kinetic.palette.rgb.gray100};
    border: 1px solid transparent;
    span {
      color: ${theme.kinetic.palette.rgb.gray800};
    }
    svg {
      fill: ${theme.kinetic.palette.rgb.gray800};
    }
  }
`;
