import { css, styled } from "@mui/material/styles";
import DialogActionsMui, {
  DialogActionsProps,
} from "@mui/material/DialogActions";
import { withTransientProps } from "../../../../lib/emotion";

type StyledDialogActionsProps = DialogActionsProps & {
  $color?: "primary" | "positive" | "negative" | "warning";
};

export const DialogActions = styled(
  DialogActionsMui,
  withTransientProps
)<StyledDialogActionsProps>`
  padding: ${({ theme }) => theme.kinetic.paddings[20]};
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);

  ${({ theme, $color }) => {
    const { rgb } = theme.kinetic.v2.palette;

    switch ($color) {
      case "primary":
        return css`
          background: ${rgb.blueBg};
          color: ${rgb.blue};
        `;
      case "positive":
        return css`
          background: ${rgb.positiveBg};
          color: ${rgb.positive};
        `;
      case "negative":
        return css`
          background: ${rgb.negativeBg};
          color: ${rgb.negative};
        `;
      case "warning":
        return css`
          background: ${rgb.warningBg};
          color: ${rgb.warning};
        `;
      default:
        return "";
    }
  }};
`;
