import { isBrowser } from "./../../src/utils/is-browser";

export const requestIdleCallback = isBrowser()
  ? (window as any).requestIdleCallback ||
    ((fn: any) => window.setTimeout(fn, 0))
  : () => undefined;

export const cancelIdleCallback = isBrowser()
  ? (window as any).cancelIdleCallback || window.clearTimeout
  : () => undefined;

// This isn't a true to spec polyfill but it is Good Enough - Zach P
