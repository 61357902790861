import CryptoJS from "crypto-js";

const SEED = process.env.NEXT_CONFIG_ENCRYPTATION_SEED;
/**
 * Encrypt a value using the CryptoJS library.
 *
 *
 * @param value
 * @returns
 */
export function encrypt(value: string): string {
  if (!SEED) {
    throw new Error("ENCRYPTION_SEED not found.");
  }
  const encryptedBytes = CryptoJS.AES.encrypt(value, SEED);

  return encryptedBytes.toString();
}

/**
 * Decrypt a value using the CryptoJS library.
 *
 * @param value
 * @returns
 */
export function decrypt(value: string): string {
  if (!SEED) {
    throw new Error("ENCRYPTION_SEED not found.");
  }
  const decryptedBytes = CryptoJS.AES.decrypt(value, SEED);

  return decryptedBytes.toString(CryptoJS.enc.Utf8);
}
