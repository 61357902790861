export const COMPANY_PREFIX = `/:companyExternalId`;

export const ROUTES = {
  home: `${COMPANY_PREFIX}`,
  profile: `${COMPANY_PREFIX}/profile`,
  leaderboard: `${COMPANY_PREFIX}/leaderboard`,
  skill: `${COMPANY_PREFIX}/competencies/:competencyExternalId/skills/:skillExternalId`,
  reps: `${COMPANY_PREFIX}/reps/coach`,
  rep: `${COMPANY_PREFIX}/reps/:repAssignmentId`,
  repInLp: `${COMPANY_PREFIX}/competencies/:competencyExternalId/reps/:repAssignmentId`,
  socialReps: `${COMPANY_PREFIX}/reps/social`,
  socialRep: `${COMPANY_PREFIX}/social-reps/:repAssignmentId`,
  socialRepInLp: `${COMPANY_PREFIX}/competencies/:competencyExternalId/social-reps/:repAssignmentId`,
  coachReps: `${COMPANY_PREFIX}/coachreps`,
  coachRep: `${COMPANY_PREFIX}/coachreps/:repAssignmentId`,
  topics: `${COMPANY_PREFIX}/topics`,
  topic: `${COMPANY_PREFIX}/topics/:topicId`,
  embeddedAssignment: `${COMPANY_PREFIX}/assignment/group`,
  learningPlanAssignment: `${COMPANY_PREFIX}/assignment/learning-plan`,
  authenticationByUrl: `${COMPANY_PREFIX}/learners/:profileExternalId`,
  maintenance: "/maintenance",
  login: "/login",
  signUp: "/sign-up",
  ssoRedirect: "/sso-redirect",
  logout: "/logout",
  privacyPolicy: "/privacy-policy",
  termsOfService: "/terms-of-service",
  error: "/error",
};

export type AvailableRoutes = keyof typeof ROUTES;

// Place here only routes that can be accessed publicly and should not be validated by the middleware
// NOTE: Do not include pages that should be validated in the middleware. E.g: login is a public page but
// we need to validate it because if the user is logged we redirect them to home, same for sign-up
export const PUBLIC_ROUTES = [
  ROUTES.privacyPolicy,
  ROUTES.termsOfService,
  ROUTES.maintenance,
  ROUTES.error,
];

export function getRoute(
  routeKey: AvailableRoutes,
  params: Record<string, string> = {}
): string {
  let route = ROUTES[routeKey];
  for (const [k, v] of Object.entries(params)) {
    route = route.replace(`:${k}`, v);
  }

  return route;
}
