import { styled } from "@mui/material/styles";
import DialogContentMui, {
  DialogContentProps,
} from "@mui/material/DialogContent";
import { withTransientProps } from "../../../../lib/emotion";

type StyledDialogContentProps = DialogContentProps & { $noPadding: boolean };

export const DialogContent = styled(
  DialogContentMui,
  withTransientProps
)<StyledDialogContentProps>`
  padding: ${({ theme, $noPadding }) =>
    $noPadding ? 0 : theme.kinetic.paddings[20]};

  .MuiDialogContent-dividers {
    border-color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayUiLight};
  }
`;
