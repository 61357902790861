import axios, { AxiosError } from "axios";
import { nanoid } from "nanoid";
import {
  MAX_FILE_SIZE,
  MAX_PHOTO_FILE_SIZE,
  MAX_VIDEO_FILE_SIZE,
  FILE_ERROR_MESSAGE,
  PHOTO_ERROR_MESSAGE,
  VIDEO_ERROR_MESSAGE,
} from "../lib/uploads";

const CLOUDINARY_UPLOAD_URL =
  "https://api.cloudinary.com/v1_1/bigspring/upload";
const CHUNK_SIZE = 1048576 * 5; // 5 mb
const RETRY_TIMEOUT = 200; // 0.2s
const RETRIES = 20;
const OTHER_MIME_TYPES = [
  "text/csv",
  "text/vtt",
  "text/srt",
  "application/x-subrip",
  "application/pdf",
];

export enum FileType {
  Csv = "CSV",
  Image = "IMAGE",
  Other = "OTHER",
  Pdf = "PDF",
  Video = "VIDEO",
  Vtt = "VTT",
  Ppt = "PPTX",
}

export type UploadConfig = {
  /**
   * FileType determines upload MIME type and file extension
   */
  type: FileType;
  /**
   * MIME type, overriding value derived from file or FileType
   */
  mimeType?: string;
  /**
   * Cloudinary API key
   */
  apiKey: string;
  /**
   * Cloudinary API secret
   */
  apiSecret: string;
  /**
   * Cloudinary upload preset
   */
  preset?: UploadPreset;
  /**
   * Upload progress handler
   */
  onProgress?: (progress: number) => void;
};

/**
 * Cloudinary upload preset
 */
type UploadPreset =
  | "skill_image_v2"
  | "flash_card_image_v2"
  | "skill_vtt"
  | "rep_image_v2"
  | "rep_audio_upload"
  | "profile_image_v2"
  | "topic_image_v2"
  | "company_image_v2"
  | "oawrxicz"
  | "pptx_certificate_template";

/**
 * Cloudinary upload REST API response
 */
type CloudinaryResponse = {
  public_id: string;
  version: number;
  signature: string;
  width: number;
  height: number;
  format: string;
  resource_type: string;
  created_at: string;
  tags: Array<string>;
  pages: number;
  bytes: number;
  type: string;
  etag: string;
  placeholder: boolean;
  url: string;
  secure_url: string;
  access_mode: string;
  original_filename: string;
  moderation: Array<string>;
  access_control: Array<string>;
  context: object;
  metadata: object;

  [futureKey: string]: unknown;
};

/**
 * Get file MIME types
 *
 * @param fileType FileType determines the MIME type
 * @returns MIME type
 */
export const getMimeType = (fileType: FileType) => {
  switch (fileType) {
    case "IMAGE":
      return "image/jpeg";
    case "VIDEO":
      return "video/mp4";
    case "VTT":
      return "text/vtt";
    case "CSV":
      return "text/csv";
    case "PDF":
      return "application/pdf";
    case "PPTX":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "OTHER":
    default:
      return "";
  }
};

/**
 * Get file extensions for media types that Cloudinary can't identify on its own
 *
 * @param fileType FileType determines the file extension
 * @returns file extension
 */
const getUnknownExtension = (fileType: FileType) => {
  switch (fileType) {
    case "VTT":
      return ".vtt";
    case "CSV":
      return ".csv";
    case "PDF":
      return ".pdf";
    case "PPTX":
      return ".pptx";
    case "OTHER":
    default:
      return "";
  }
};

const getSignature = async (
  apiSecret: string,
  data: Record<string, string | boolean>
) => {
  const signatureText =
    Object.keys(data)
      .map((key) => `${key}=${data[key]}`)
      .join("&") + apiSecret;

  const encoder = new TextEncoder();
  const encodedSignature = encoder.encode(signatureText);

  const hashBuffer = await crypto.subtle.digest("SHA-256", encodedSignature);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const slice = (file: any, start: number, end: number) => {
  const _slice = file.mozSlice
    ? file.mozSlice
    : file.webkitSlice
    ? file.webkitSlice
    : file.slice
    ? file.slice
    : () => file;

  return _slice.bind(file)(start, end);
};

/**
 * Uploads a file to Cloudinary in chunks
 *
 * @param file file to upload
 * @param config upload config
 * @returns Cloudinary upload response
 */
export const uploadToCloudinary = async (file: File, config: UploadConfig) => {
  const mimeType = file.type || getMimeType(config.type);

  // Check for a valid file
  if (!file) {
    throw new Error("No file provided");
  }
  if (mimeType.startsWith("image") && file.size > MAX_PHOTO_FILE_SIZE) {
    throw new Error(PHOTO_ERROR_MESSAGE);
  }
  if (mimeType.startsWith("video") && file.size > MAX_VIDEO_FILE_SIZE) {
    throw new Error(VIDEO_ERROR_MESSAGE);
  }
  if (OTHER_MIME_TYPES.includes(mimeType) && file.size > MAX_FILE_SIZE) {
    throw new Error(FILE_ERROR_MESSAGE);
  }

  const timestamp = Math.round(new Date().getTime() / 1000).toString();
  const uploadId = nanoid();
  const publicId = `${uploadId}${getUnknownExtension(config.type)}`;
  const preset = config.preset ?? "oawrxicz";

  const send = async (
    partOfFile: File,
    start: number,
    end: number
  ): Promise<CloudinaryResponse> => {
    // Signature data properties must be sorted in alphabetical order
    const signatureData = {
      public_id: publicId,
      timestamp,
      upload_preset: preset,
    };

    const data = {
      ...signatureData,
      signature: await getSignature(config.apiSecret, signatureData),
      signature_algorithm: "sha256",
      api_key: config.apiKey,
      start,
      end,
      file: partOfFile,
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value as string);
    }

    const response = await axios.request<CloudinaryResponse>({
      method: "POST",
      url: CLOUDINARY_UPLOAD_URL,
      data: formData,
      headers: {
        "Content-Range": `bytes ${start}-${end - 1}/${file.size}`,
        "X-Unique-Upload-Id": uploadId,
      },
    });

    config?.onProgress?.(Math.round((end * 100) / file.size));

    return response.data;
  };

  const loop = async (
    start = 0,
    retriesLeft = RETRIES
  ): Promise<CloudinaryResponse> => {
    // Get the chunk range and chunk the file
    const nextChunk = start + CHUNK_SIZE;
    const end = Math.min(nextChunk, file.size);
    const body = slice(file, start, end);
    try {
      const cloudinaryResponse = await send(body, start, end);

      if (end < file.size) {
        return loop(start + CHUNK_SIZE, retriesLeft);
      }

      return cloudinaryResponse;
    } catch (err) {
      const { response, message } = err as AxiosError<{ error: Error }>;
      const progress = `${Math.round((100 * end) / file.size)}%`;
      const cloudinaryErrorMessage = response?.data?.error?.message || message;
      const defaultError = `Failed to upload chunk at ${start}B / ${progress}: ${cloudinaryErrorMessage}`;

      // Fail for client errors
      const status = response?.status || 0;
      if (status > 399 && status < 500) {
        throw new Error(defaultError);
      } else {
        console.warn(defaultError);
      }

      // Retry for server errors
      if (retriesLeft > 0) {
        const retryTimeout = RETRY_TIMEOUT * 2 * (RETRIES - retriesLeft);
        console.warn(
          `Retrying chunk at ${start}B in ${retryTimeout}ms. Retries left: ${retriesLeft}`
        );

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(loop(start, retriesLeft - 1));
          }, retryTimeout);
        });
      }

      throw new Error(`Too many retries: ${message}`, {
        cause: { message: response?.statusText, code: response?.status },
      });
    }
  };

  const cloudinaryResponse = await loop();

  return cloudinaryResponse;
};
