import {
  identify,
  track,
  hardReset,
  softReset,
  page,
  group,
  AdminIdentificationType,
  PwaIdentificationType,
} from "../../lib/segment";
import { FetchPolicy } from "@apollo/client";
import { requestIdleCallback } from "../../lib/idle";

export type SegmentPropFunctions = {
  identify: (
    identifyData: PwaIdentificationType | AdminIdentificationType
  ) => Promise<unknown>;
  track: (
    name: string,
    properties: Record<string, unknown>,
    params?: { fetchPolicy: FetchPolicy }
  ) => Promise<unknown>;
  page: (
    name: string,
    properties: Record<string, unknown>,
    params?: { fetchPolicy: FetchPolicy }
  ) => Promise<unknown>;
  softReset: () => Promise<unknown>;
  hardReset: () => Promise<unknown>;
  group: (
    companyId: string,
    properties: Record<string, unknown>
  ) => Promise<unknown>;
};

export type SegmentProps = {
  segment: SegmentPropFunctions;
};

const defer = (thunk: () => Promise<any>) =>
  new Promise((resolve, reject) => {
    requestIdleCallback(() => {
      thunk().then(resolve).catch(reject);
    });
  });

export function useSegment(segmentEnabled = false): SegmentPropFunctions {
  return {
    group: (companyId: string, properties: Record<string, unknown>) => {
      if (!segmentEnabled) {
        console.warn("Segment Disabled.");

        return Promise.resolve();
      }

      return defer(async () => {
        await group(companyId, properties);
      });
    },
    identify: (
      identifyData: PwaIdentificationType | AdminIdentificationType
    ) => {
      if (!segmentEnabled) {
        console.warn("Segment Disabled.");

        return Promise.resolve();
      }

      return defer(() => {
        return identify(identifyData);
      });
    },
    track: (name: string, properties: Record<string, unknown>) => {
      if (!segmentEnabled) {
        console.warn("Segment Disabled.");

        return Promise.resolve();
      }

      return defer(async () => {
        track(name, properties);
      });
    },
    page: (name: string, properties: Record<string, unknown>) => {
      if (!segmentEnabled) {
        console.warn("Segment Disabled.");

        return Promise.resolve();
      }

      return defer(async () => {
        page(name, properties);
      });
    },
    softReset: () => {
      if (!segmentEnabled) {
        console.warn("Segment Disabled.");

        return Promise.resolve();
      }

      return defer(() => {
        softReset();
        if ((window as any).mixpanel) {
          (window as any).mixpanel.cookie.clear();
        }

        return Promise.resolve();
      });
    },
    hardReset: () => {
      if (!segmentEnabled) {
        console.warn("Segment Disabled.");

        return Promise.resolve();
      }

      return defer(() => {
        hardReset();
        if ((window as any).mixpanel) {
          (window as any).mixpanel.cookie.clear();
        }

        return Promise.resolve();
      });
    },
  };
}
