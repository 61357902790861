import React, { PropsWithChildren } from "react";
import { BSPBody1 } from "../../../../BSPBody1";
import { BaseDialog, BaseDialogCustomProps } from "../BaseDialog";

export type ConfirmDialogCustomProps = BaseDialogCustomProps;

type Props = {
  show: boolean;
  customProps?: ConfirmDialogCustomProps;
  onCancel: () => void;
  onSubmit: (dontShowAgain: boolean) => void;
};

export function ConfirmDialog({
  show,
  customProps,
  onCancel,
  onSubmit,
  children,
}: PropsWithChildren<Props>) {
  return (
    <BaseDialog
      show={show}
      customProps={customProps}
      cancelButtonText="Cancel"
      submitButtonText="Ok"
      onClose={onCancel}
      onSubmit={onSubmit}
    >
      {typeof children === "string" ? (
        <BSPBody1 dangerouslySetInnerHTML={{ __html: children }} />
      ) : (
        children
      )}
    </BaseDialog>
  );
}
