import {
  ApolloClient,
  FetchResult,
  NextLink,
  NormalizedCacheObject,
  Observable,
  Operation,
} from "@apollo/client";
import { serialize, parse } from "cookie";
import { encrypt } from "./encryption";
import {
  AuthByUrlDocument,
  AuthByUrlMutation,
  AuthByUrlMutationVariables,
  AuthRefreshDocument,
  AuthRefreshMutation,
  AuthRefreshMutationVariables,
  AuthenticationSource,
} from "@gql/generated/graphql";
import { getRoute } from "@config/routes";

const env = process.env.NEXT_PUBLIC_ENVIRONMENT;

export enum AUTH_KEYS {
  COMPANY_EXTERNAL_ID = "companyExternalId",
  COMPANY_PROFILE_ID = "companyProfileId",
  COMPANY_PROFILE_EXTERNAL_ID = "companyProfileExternalId",
  AUTHENTICATION_BY_URL = "authenticationByUrl",
  AUTHOVERRIDE = `authoverride_${env}` as any,
  AUTHOVERRIDE_COMPANY_EXTERNAL_ID = `authoverride:companyExternalId_${env}` as any,
  AUTHOVERRIDE_COMPANY_PROFILE_ID = `authoverride:companyProfileId_${env}` as any,
  AUTHOVERRIDE_COMPANY_PROFILE_EXTERNAL_ID = `authoverride:companyProfileExternalId_${env}` as any,
  UNAUTHENTICATED_PATH = "unauthenticatedPath",
  WORKSPACES = "workspaces",
}

export type Workspace = {
  companyExternalId: string;
  companyProfileId: string;
};

type GetAuthOverrideSessionStorageItems = {
  companyProfileId: string;
  companyExternalId: string;
  companyProfileExternalId: string;
};

type Path = {
  pathname: string;
  search: string;
  hash: string;
};

export function removePreviousUrl(): void {
  removeItem(AUTH_KEYS.UNAUTHENTICATED_PATH);
}

export function recoverPreviousUrl(): Path | null {
  const url = getItem(AUTH_KEYS.UNAUTHENTICATED_PATH);

  let payload: Path | null = null;
  if (url) {
    payload = JSON.parse(url) as Path;

    removePreviousUrl();
  }

  return payload;
}

export function storeWorkspace(
  companyExternalId: string,
  companyProfileId: string
) {
  setItem(AUTH_KEYS.COMPANY_EXTERNAL_ID, companyExternalId);
  setItem(AUTH_KEYS.COMPANY_PROFILE_ID, companyProfileId);
}

export function storeAuthenticationByUrl() {
  setItem(AUTH_KEYS.AUTHENTICATION_BY_URL, JSON.stringify(true));
}

/**
 * As it is a cookie it is present on the server, just not under "document.cookie"
 */
export function getCompanyExternalId(): string | null {
  return getItem(AUTH_KEYS.COMPANY_EXTERNAL_ID) || null;
}

export function getCompanyProfileId(): string | null {
  return getItem(AUTH_KEYS.COMPANY_PROFILE_ID) || null;
}

function getCompanyProfileExternalId(): string | null {
  return getItem(AUTH_KEYS.COMPANY_PROFILE_EXTERNAL_ID) || null;
}

/**
 * This is used only to reuse it during auth override.
 * Use company profile external ID of current logged user (Me query) for any other thing
 */
export function storeCompanyProfileExternalId(value: string) {
  setItem(AUTH_KEYS.COMPANY_PROFILE_EXTERNAL_ID, value);
}

/**
 * This is use to store all the available workspaces of the current user.
 * We use it later on in the middleware to validate whether the user has access
 * to the workspace they are trying to switch or not when they enter the it via URL
 */
export function storeWorkspaces(workspaces: Workspace[]) {
  setItem(AUTH_KEYS.WORKSPACES, encrypt(JSON.stringify(workspaces)));
}

export function removeWorkspaces() {
  removeItem(AUTH_KEYS.WORKSPACES);
}

export function getAuthOverrideSessionStorageItems(): GetAuthOverrideSessionStorageItems | null {
  if (!amIInAuthOverrideMode()) {
    return null;
  }

  return {
    companyExternalId: getItem(
      AUTH_KEYS.AUTHOVERRIDE_COMPANY_EXTERNAL_ID
    ) as string,
    companyProfileId: getItem(
      AUTH_KEYS.AUTHOVERRIDE_COMPANY_PROFILE_ID
    ) as string,
    companyProfileExternalId: getItem(
      AUTH_KEYS.AUTHOVERRIDE_COMPANY_PROFILE_EXTERNAL_ID
    ) as string,
  };
}

export function createAuthOverrideSessionStorageItems() {
  setItem(AUTH_KEYS.AUTHOVERRIDE, JSON.stringify(true));
  setItem(
    AUTH_KEYS.AUTHOVERRIDE_COMPANY_PROFILE_ID,
    getCompanyProfileId() as string
  );
  const profileExternalId = getCompanyProfileExternalId();
  if (profileExternalId) {
    setItem(
      AUTH_KEYS.AUTHOVERRIDE_COMPANY_PROFILE_EXTERNAL_ID,
      profileExternalId as string
    );
  }
  setItem(
    AUTH_KEYS.AUTHOVERRIDE_COMPANY_EXTERNAL_ID,
    getCompanyExternalId() as string
  );
}

export function logoutAuthOverrideSessionStorageItems() {
  const superUser = getAuthOverrideSessionStorageItems();

  if (!superUser) {
    return;
  }

  cleanSessionStorageItems(true, true);

  storeWorkspace(superUser.companyExternalId, superUser.companyProfileId);
}

/**
 * @param cleanAuthOverride Remove auth override session cookies
 * @param cleanLatestSelectedWorkspace Remove latest selected workspace by user. If false
 * user will keep the selected workspace after logout and login again
 */
export function cleanSessionStorageItems(
  cleanAuthOverride = true,
  cleanLatestSelectedWorkspace = false
) {
  console.debug("Cleaning session storage items");
  removeItem(AUTH_KEYS.AUTHENTICATION_BY_URL);

  if (cleanLatestSelectedWorkspace) {
    removeItem(AUTH_KEYS.COMPANY_PROFILE_ID);
    removeItem(AUTH_KEYS.COMPANY_PROFILE_EXTERNAL_ID);
    removeItem(AUTH_KEYS.COMPANY_EXTERNAL_ID);
  }

  if (cleanAuthOverride) {
    cleanAuthOverrideSessionStorageItems();
  }
}

function cleanAuthOverrideSessionStorageItems() {
  if (!amIInAuthOverrideMode()) {
    return;
  }

  removeItem(AUTH_KEYS.AUTHOVERRIDE);
  removeItem(AUTH_KEYS.AUTHOVERRIDE_COMPANY_EXTERNAL_ID);
  removeItem(AUTH_KEYS.AUTHOVERRIDE_COMPANY_PROFILE_ID);
  removeItem(AUTH_KEYS.AUTHOVERRIDE_COMPANY_PROFILE_EXTERNAL_ID);
}

export function amIInAuthOverrideMode() {
  return !!getItem(AUTH_KEYS.AUTHOVERRIDE);
}

export function isAuthenticationByUrl() {
  return !!getItem(AUTH_KEYS.AUTHENTICATION_BY_URL);
}

export const fetchNewTokenByRefreshToken = async (
  client: ApolloClient<NormalizedCacheObject>
) => {
  const { data } = await client.mutate<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >({
    mutation: AuthRefreshDocument,
    variables: { source: AuthenticationSource.App },
  });

  return data?.authRefresh ?? false;
};

let refreshPromise: Promise<void> | null = null;

export function fetchNewTokenAndRetry(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  operation: Operation,
  forward: NextLink
) {
  return new Observable<FetchResult>((observer) => {
    const fetchNewTokenSuccess = () => {
      const subscriber = {
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      };

      console.debug("Successfully refreshed tokens, retrying operation");

      return forward(operation).subscribe(subscriber);
    };

    const fetchNewTokenError = (error: unknown) => {
      console.error("observable fetchNewTokenByRefreshToken error:", error);
      cleanSessionStorageItems();
      removeWorkspaces();
      console.debug("cleanup cookies done");

      console.error("Failed to refresh tokens, redirecting to login page");
      window.location.replace(getRoute("login"));

      observer.error(error);
      console.error("Failed to refresh tokens");
      console.error(error);
    };

    if (!refreshPromise) {
      refreshPromise = fetchNewTokenByRefreshToken(apolloClient)
        .then(() => {
          refreshPromise = null;
          fetchNewTokenSuccess();
        })
        .catch((error) => {
          refreshPromise = null;
          fetchNewTokenError(error);
        });
    } else {
      refreshPromise.then(fetchNewTokenSuccess).catch(fetchNewTokenError);
    }
  });
}

export async function fetchNewTokenByExternalId(
  apolloClient: ApolloClient<object>,
  variables: AuthByUrlMutationVariables
) {
  try {
    const result = await apolloClient.mutate<
      AuthByUrlMutation,
      AuthByUrlMutationVariables
    >({
      mutation: AuthByUrlDocument,
      variables,
    });

    return result.data?.authByURL || null;
  } catch (error) {
    console.warn(error);

    return null;
  }
}

export function getItem(key: AUTH_KEYS) {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return null;
  }

  return parse(document.cookie)[key];
}

/**
 * Store a new cookie
 *
 * @param key Cookie name
 * @param value Cookie value
 * @returns
 */
function setItem(key: AUTH_KEYS, value: string) {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return null;
  }

  const cookie = serialize(key as string, value, {
    domain: window.location.hostname,
    path: "/",
    maxAge: 60 * 60 * 24 * 365,
    sameSite: "lax",
    secure: true,
  });
  document.cookie = cookie;
}

function removeItem(key: AUTH_KEYS) {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return null;
  }

  const cookie = serialize(key as string, "", {
    domain: window.location.hostname,
    path: "/",
    maxAge: 0,
  });
  document.cookie = cookie;
}
