import langmap from "langmap";

enum Language {
  Aa = "aa",
  Ab = "ab",
  Ae = "ae",
  Af = "af",
  Ak = "ak",
  Am = "am",
  An = "an",
  Ar = "ar",
  As = "as",
  Av = "av",
  Ay = "ay",
  Az = "az",
  Ba = "ba",
  Be = "be",
  Bg = "bg",
  Bh = "bh",
  Bi = "bi",
  Bm = "bm",
  Bn = "bn",
  BnBd = "bn_BD",
  Bo = "bo",
  Br = "br",
  Bs = "bs",
  Ca = "ca",
  Ce = "ce",
  Ch = "ch",
  Co = "co",
  Cr = "cr",
  Cs = "cs",
  Cu = "cu",
  Cv = "cv",
  Cy = "cy",
  Da = "da",
  De = "de",
  Dv = "dv",
  Dz = "dz",
  Ee = "ee",
  El = "el",
  En = "en",
  Eo = "eo",
  Es = "es",
  Et = "et",
  Eu = "eu",
  Fa = "fa",
  Ff = "ff",
  Fi = "fi",
  Fil = "fil",
  Fj = "fj",
  Fo = "fo",
  Fr = "fr",
  Fy = "fy",
  Ga = "ga",
  Gd = "gd",
  Gl = "gl",
  Gn = "gn",
  Gu = "gu",
  Gv = "gv",
  Ha = "ha",
  He = "he",
  Hi = "hi",
  Ho = "ho",
  Hr = "hr",
  Ht = "ht",
  Hu = "hu",
  Hy = "hy",
  Hz = "hz",
  Ia = "ia",
  Id = "id",
  Ie = "ie",
  Ig = "ig",
  Ii = "ii",
  Ik = "ik",
  Io = "io",
  Is = "is",
  It = "it",
  Iu = "iu",
  Ja = "ja",
  Jv = "jv",
  Ka = "ka",
  Kg = "kg",
  Ki = "ki",
  Kj = "kj",
  Kk = "kk",
  Kl = "kl",
  Km = "km",
  Kn = "kn",
  Ko = "ko",
  Kr = "kr",
  Ks = "ks",
  Ku = "ku",
  Kv = "kv",
  Kw = "kw",
  Ky = "ky",
  La = "la",
  Lb = "lb",
  Lg = "lg",
  Li = "li",
  Ln = "ln",
  Lo = "lo",
  Lt = "lt",
  Lu = "lu",
  Lv = "lv",
  Mg = "mg",
  Mh = "mh",
  Mi = "mi",
  Mk = "mk",
  Ml = "ml",
  Mn = "mn",
  Mr = "mr",
  Ms = "ms",
  Mt = "mt",
  My = "my",
  Na = "na",
  Nb = "nb",
  Nd = "nd",
  Ne = "ne",
  Ng = "ng",
  Nl = "nl",
  Nn = "nn",
  No = "no",
  Nr = "nr",
  Nv = "nv",
  Ny = "ny",
  Oc = "oc",
  Oj = "oj",
  Om = "om",
  Or = "or",
  Os = "os",
  Pa = "pa",
  Pi = "pi",
  Pl = "pl",
  Ps = "ps",
  Pt = "pt",
  PtBr = "pt_BR",
  Qu = "qu",
  Rm = "rm",
  Rn = "rn",
  Ro = "ro",
  Ru = "ru",
  Rw = "rw",
  Sa = "sa",
  Sc = "sc",
  Sd = "sd",
  Se = "se",
  Sg = "sg",
  Si = "si",
  Sk = "sk",
  Sl = "sl",
  Sm = "sm",
  Sn = "sn",
  So = "so",
  Sq = "sq",
  Sr = "sr",
  Ss = "ss",
  St = "st",
  Su = "su",
  Sv = "sv",
  Sw = "sw",
  Ta = "ta",
  Te = "te",
  Tg = "tg",
  Th = "th",
  Ti = "ti",
  Tk = "tk",
  Tl = "tl",
  Tn = "tn",
  To = "to",
  Tr = "tr",
  Ts = "ts",
  Tt = "tt",
  Tw = "tw",
  Ty = "ty",
  Ug = "ug",
  Uk = "uk",
  Ur = "ur",
  Uz = "uz",
  Ve = "ve",
  Vi = "vi",
  ViVn = "vi_VN",
  Vo = "vo",
  Wa = "wa",
  Wo = "wo",
  Xh = "xh",
  Yi = "yi",
  Yo = "yo",
  Za = "za",
  Zh = "zh",
  ZhCn = "zh_CN",
  Zu = "zu",
}

// langmap's language codes differ from those in our database, so we need to patch langmap
// https://github.com/mozilla/language-mapping-list/blob/master/language-mapping-list.js

langmap[Language.As] = { nativeName: "অসমীয়া", englishName: "Assasmese" };
langmap[Language.Tl] = { nativeName: "ᜆᜄᜎᜓᜄ᜔", englishName: "Tagalog" };
langmap[Language.Si] = langmap["si-LK"]; // We use 'si' internally, langmap only has LK modifier
langmap[Language.BnBd] = langmap["bn-BD"];
langmap["bn_bd"] = langmap["bn-BD"];
langmap["zh-CN"] = {
  nativeName: "简体中文",
  englishName: "Chinese (Simplified)",
};
langmap[Language.Gu] = langmap["gu-IN"];
langmap[Language.Or] = langmap["or-IN"];

export { langmap };

export const getLanguageAndRegion = (languageCode: Language) =>
  languageCode?.split(/[-_]/) ?? [null, null];

export const getIsoLanguage = (languageCode: Language) => {
  const [_language, _region] = getLanguageAndRegion(languageCode);
  if (!_language) {
    return null;
  }
  const language = _language.toLowerCase();
  const region = _region?.toUpperCase();
  const isoCode = region ? `${language}-${region}` : language;

  return isoCode;
};

export const getLanguageFullName = (
  languageCode: Language,
  nativeName = true
) => {
  const isoCode = getIsoLanguage(languageCode);

  if (!isoCode) {
    return languageCode;
  }

  const nameKey = nativeName ? "nativeName" : "englishName";

  return langmap[isoCode]?.[nameKey] || isoCode;
};
