import { styled } from "@mui/material/styles";

export const BSPSubtext = styled("span")`
  font-family: "Roboto", var(--bigspring-primary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.005em;
`;
