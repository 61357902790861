"use client";

import { createContext, useContext, PropsWithChildren, useState } from "react";
import { useNotification } from "@bigspring/core-components/helpers";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { AvailableRoutes, getRoute } from "@config/routes";
import { getCompanyExternalId } from "@utils/auth";
import { NavigateOptions } from "next/dist/shared/lib/app-router-context.shared-runtime";

type CustomPushOptions = NavigateOptions & { askBeforeLeaving?: boolean };
type CustomPush = (
  url: AvailableRoutes | { pathname: AvailableRoutes; search?: string },
  params?: Record<string, string>,
  options?: CustomPushOptions
) => Promise<boolean>;

type CustomReplaceOptions = NavigateOptions & {
  askBeforeLeaving?: boolean;
};
type CustomReplace = (
  url: AvailableRoutes | { pathname: AvailableRoutes; search?: string },
  params?: Record<string, string>,
  options?: CustomReplaceOptions
) => Promise<boolean>;

type Location = {
  key: AvailableRoutes | null;
};

type CustomRouterContext = Omit<
  ReturnType<typeof useRouter>,
  "push" | "replace"
> & {
  pathname: string;
  query: ReturnType<typeof useSearchParams> | Record<string, never>;
  location: Location;
  push: CustomPush;
  replace: CustomReplace;
  setWarnUnsavedChanges: (warn: boolean) => void;
};

const Context = createContext<CustomRouterContext>({
  pathname: "",
  query: {},
  location: {
    key: null,
  },
  push: async () => false,
  replace: async () => false,
  refresh: () => undefined,
  back: () => undefined,
  forward: () => undefined,
  prefetch: () => undefined,
  setWarnUnsavedChanges: () => undefined,
});

export function CustomRouterProvider({ children }: PropsWithChildren<unknown>) {
  // TODO: Save this in current session to complete the behavior it had with react-router
  const [location, setLocation] = useState<Location>({ key: null });

  const {
    push: nextPush,
    replace: nextReplace,
    ...restNextRouterProps
  } = useRouter();
  const pathname = usePathname();
  const query = useSearchParams();

  const { ask } = useNotification();

  const [warnUnsavedChanges, setWarnUnsavedChanges] = useState(false);

  const push: CustomPush = async (url, params, options) => {
    // askBeforeLeaving prop takes precedence over internal state. That way we can force the alert to appear or not
    const shouldAsk =
      typeof options?.askBeforeLeaving === "boolean"
        ? options.askBeforeLeaving
        : warnUnsavedChanges;

    if (
      shouldAsk &&
      !(await ask(
        "Are you sure you want to leave this page? You have unsaved changes"
      ))
    ) {
      return false;
    }

    setWarnUnsavedChanges(false);

    // If the companyExternalId is in the URL, re-add it in the new params for the new URL.
    const companyExternalId = getCompanyExternalId();
    if (companyExternalId) {
      params = { companyExternalId, ...params };
    }

    if (typeof url === "object") {
      if (url.search) {
        nextPush(`${getRoute(url.pathname, params)}?${url.search}`, options);
      } else {
        nextPush(getRoute(url.pathname, params), options);
      }

      setLocation({ key: url.pathname });
    } else {
      nextPush(getRoute(url, params), options);

      setLocation({ key: url });
    }

    return true;
  };

  const replace: CustomReplace = async (url, params, options) => {
    // askBeforeLeaving prop takes precedence over internal state. That way we can force the alert to appear or not
    const shouldAsk =
      typeof options?.askBeforeLeaving === "boolean"
        ? options.askBeforeLeaving
        : warnUnsavedChanges;

    if (
      shouldAsk &&
      !(await ask(
        "Are you sure you want to leave this page? You have unsaved changes"
      ))
    ) {
      return false;
    }

    setWarnUnsavedChanges(false);

    const companyExternalId = getCompanyExternalId();
    if (companyExternalId) {
      params = { companyExternalId, ...params };
    }

    if (typeof url === "object") {
      if (url.search) {
        nextReplace(`${getRoute(url.pathname, params)}?${url.search}`, options);
      } else {
        nextReplace(getRoute(url.pathname, params), options);
      }

      setLocation({ key: url.pathname });
    } else {
      nextReplace(getRoute(url, params), options);

      setLocation({ key: url });
    }

    return true;
  };

  return (
    <Context.Provider
      value={{
        pathname,
        query,
        location,
        setWarnUnsavedChanges,
        push,
        replace,
        ...restNextRouterProps,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useCustomRouter() {
  return useContext(Context);
}
