import { MutationFunctionOptions } from "@apollo/client";

const CHUNK_SIZE = 1048576 * 5; // 5 MB
const RETRY_INTERVAL = 5; // 5 seconds
const THREADS_QUANTITY = 5; // 5 XMLHttpRequest requested "at the same time".
const MAX_RETRIES_PER_THREAD = 20; // 20 retries per thread.
const MAX_RETRIES = 100; // 100 retries per thread.
const REQUEST_TIMEOUT = 30; // 30 seconds to cancel the XMLHttpRequest requested.

type Environment = "local" | "development" | "staging" | "production";
type FileType = "VIDEO";
type UploadedPart = { partNumber: number; eTag: string };

// GetSignedUrlsMutation
enum MediaType {
  Csv = "CSV",
  Image = "IMAGE",
  Other = "OTHER",
  Pdf = "PDF",
  Video = "VIDEO",
  Vtt = "VTT",
}
// interface IGetSignedUrlsMutationVariables {
//   mimeType: string
//   mediaType: MediaType
//   fileSize: number
//   numberOfParts: number
// }
type GetSignedUrlPart = { partNumber: number; signedUrl: string };
interface GetMultipartUploadDataResponse {
  mediaId: string;
  fileId: string;
  fileKey: string;
  parts: Array<GetSignedUrlPart>;
}

// FinalizeMultipartUpload
// type FinalizeMultipartUploadPart = {
//   eTag: string
//   partNumber: number
// }
// interface IFinalizeMultipartUploadMutationVariables {
//   mediaId: string
//   fileId: string
//   fileKey: string
//   parts: Array<FinalizeMultipartUploadPart> | FinalizeMultipartUploadPart
// }
interface FinalizeMultipartUploadResponse {
  id: string;
  mimeType: string;
  location: string | null;
  playbackId: string;
}

// S3Uploader args
type UploaderS3Args<
  GetMultipartUploadData,
  GetMultipartUploadDataVariables,
  FinalizeMultipartUploadMutation,
  FinalizeMultipartUploadMutationVariables
> = {
  file: File;
  type: FileType;
  environment: Environment;
  getMultipartUploadData: (
    options?: MutationFunctionOptions<
      GetMultipartUploadData,
      GetMultipartUploadDataVariables
    >
  ) => Promise<GetMultipartUploadDataResponse>;
  finalizeMultipartUploadData: (
    options?: MutationFunctionOptions<
      FinalizeMultipartUploadMutation,
      FinalizeMultipartUploadMutationVariables
    >
  ) => Promise<FinalizeMultipartUploadResponse>;
  onComplete?: (media: FinalizeMultipartUploadResponse) => void;
  onProgress?: (progress: {
    sent: number;
    total: number;
    percentage: number;
  }) => void;
  onError?: (error: Error) => void;
  onAbort?: () => void;
};

/**
 * Uploads a file to Cloudinary in chunks
 *
 * @param file file to upload
 * @param config upload config
 * @returns Cloudinary upload response
 */
export class UploaderS3<
  GetMultipartUploadData,
  GetMultipartUploadDataVariables,
  FinalizeMultipartUploadMutation,
  FinalizeMultipartUploadMutationVariables
> {
  /**
   *
   */
  private chunkSize: number;
  /**
   *
   */
  // private threadsQuantity: number
  /**
   *
   */
  private numberOfParts: number;
  /**
   *
   */
  private environment: Environment;
  /**
   *
   */
  private file: File;
  /**
   * MIME type, overriding value derived from file or FileType
   */
  private mimeType: string;
  /**
   * FileType determines upload MIME type and file extension
   */
  private type: FileType;
  /**
   *
   */
  private aborted: boolean;
  /**
   *
   */
  private uploadedSize: number;
  /**
   *
   */
  private retries: number;
  /**
   *
   */
  private progressCache: { [key: number]: any };
  /**
   *
   */
  private activeConnections: { [key: number]: XMLHttpRequest };
  /**
   *
   */
  private parts: Array<GetSignedUrlPart>;
  /**
   *
   */
  private uploadedParts: Array<UploadedPart>;
  /**
   *
   */
  private mediaId: string | null;
  /**
   *
   */
  private media: FinalizeMultipartUploadResponse | null;
  /**
   *
   */
  private fileId: string | null;
  /**
   *
   */
  private fileKey: string | null;
  /**
   *
   */
  private logger: ConsoleLogger;
  /**
   *
   */
  private networkStatus: "low" | "normal" | "high";
  /**
   *
   */
  private times: {
    time: number;
    part: number;
    timeout: number;
    threads: number;
    result: "success" | "timeout" | "error";
  }[];
  /**
   *
   */
  private getMultipartUploadData: (
    options?: MutationFunctionOptions<any, any>
  ) => Promise<GetMultipartUploadDataResponse>;
  /**
   *
   */
  private finalizeMultipartUploadData: (
    options?: MutationFunctionOptions<any, any>
  ) => Promise<FinalizeMultipartUploadResponse>;
  /**
   * Upload progress handler
   */
  private onComplete: (media: FinalizeMultipartUploadResponse) => void;
  /**
   * Upload progress handler
   */
  private onProgress: (progress: {
    sent: number;
    total: number;
    percentage: number;
  }) => void;
  /**
   * Error handler
   */
  private onError: (error: Error) => void;
  /**
   * Abort handler
   */
  private onAbort: () => void;

  /**
   * Constructor
   *
   * @param args
   */
  constructor(
    args: UploaderS3Args<
      GetMultipartUploadData,
      GetMultipartUploadDataVariables,
      FinalizeMultipartUploadMutation,
      FinalizeMultipartUploadMutationVariables
    >
  ) {
    const LOG_LEVEL = args.environment === "local" ? "debug" : "log";

    this.file = args.file;
    this.chunkSize = this._getChunkSize();
    this.numberOfParts = Math.ceil(this.file.size / this.chunkSize);
    this.type = "VIDEO";
    this.mimeType = this._getMimeTypeByFileType(this.file.type);
    this.aborted = false;
    this.uploadedSize = 0;
    this.environment = args.environment;
    this.retries = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.media = null;
    this.mediaId = null;
    this.fileId = null;
    this.fileKey = null;
    this.logger = new ConsoleLogger({ level: LOG_LEVEL });
    this.times = [];
    this.networkStatus = "low";
    this.getMultipartUploadData =
      args.getMultipartUploadData ||
      (async () => {
        this.logger.debug("Not handled getMultipartUploadData");

        return { mediaId: "", fileId: "", fileKey: "", parts: [] };
      });
    this.finalizeMultipartUploadData =
      args.finalizeMultipartUploadData ||
      (async () => {
        this.logger.debug("Not handled finalizeMultipartUploadData");

        return { id: "", location: "", mimeType: "", playbackId: "" };
      });
    this.onComplete =
      args.onComplete ||
      ((media) => {
        this.logger.debug("Not handled complete", { media });

        return;
      });
    this.onProgress =
      args.onProgress ||
      (({ sent, total, percentage }) => {
        this.logger.debug("Not handled progress", { sent, total, percentage });

        return;
      });
    this.onError =
      args.onError ||
      ((error: Error) => {
        this.logger.debug("Not handled error", error);

        return;
      });
    this.onAbort =
      args.onAbort ||
      (() => {
        this.logger.debug("Not handled abort");

        return;
      });
  }

  /**
   *
   */
  public async start() {
    void this.initialize();
  }

  /**
   *
   */
  public abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }

  /**
   *
   */
  private async initialize() {
    try {
      const { mediaId, fileId, fileKey, parts } =
        await this.getMultipartUploadData({
          variables: {
            mimeType: this.mimeType,
            mediaType: MediaType.Video,
            fileSize: this.file.size,
            numberOfParts: this.numberOfParts,
          },
        });
      this.mediaId = mediaId;
      this.fileId = fileId;
      this.fileKey = fileKey;
      this.parts.push(...parts);

      this.logger.debug(`FILE DATA:`, {
        mimeType: this.mimeType,
        mediaType: this.type,
        fileSize: this.file.size,
        numberOfParts: this.numberOfParts,
      });

      await this.sendNext();
    } catch (error) {
      if (error instanceof Error) {
        await this.complete(error);
      } else {
        await this.complete(new Error("Error"));
      }
    }
  }

  /**
   *
   * @returns
   */
  private async sendNext() {
    if (this.retries >= this._getMaxRetries()) {
      return;
    }

    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this._getThreadsQuantity()) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.logger.debug("Upload completed succesfully!!");
        await this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.partNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = async () => {
        await this.sendNext();
      };

      try {
        await this.sendChunk(chunk, part, sendChunkStarted);
        await this.sendNext();
      } catch (error) {
        // Signal of abort detected. Upload aborted.
        if (this.aborted) {
          if (Object.keys(this.activeConnections).length) {
            delete this.activeConnections[part.partNumber - 1];
          } else {
            await this.complete(new Error("Upload aborted."));
          }

          return;
        }

        this.retries++;
        // If we still have tries...
        if (this.retries < this._getMaxRetries()) {
          // If many connection fails, don't retry many times, delete connections until having one.
          if (Object.keys(this.activeConnections).length > 1) {
            delete this.activeConnections[part.partNumber - 1];
            this.parts.push(part);
            // Retry the unique connection.
          } else {
            this.logger.log(
              `PART: ${part.partNumber} - Try number: ${
                this.retries
              }/${this._getMaxRetries()} - Retrying in ${this._getRetryIntervalInSecs()} secs...`
            );
            this.parts.push(part);

            setTimeout(async () => {
              void this.sendNext();
            }, this._getRetryIntervalInSecs() * 1000);
          }
          // If we don't have more retries, mark the process as Error.
        } else {
          this.logger.log(
            `PART: ${part.partNumber} - Try number: ${this.retries} - The Upload failed!!`
          );
          if (!Object.keys(this.activeConnections).length)
            await this.complete(new Error("Max number of retries reached."));
        }
      }
    }
  }

  /**
   *
   * @param error
   * @returns
   */
  private async complete(error?: Error) {
    if (error) {
      this.aborted ? this.onAbort() : this.onError(error);

      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      if (error instanceof Error) {
        this.onError(error);
      } else {
        this.onError(new Error(JSON.stringify(error)));
        await this.complete(new Error("Error"));
      }
    }
  }

  /** */
  private async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        mediaId: this.mediaId,
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      };

      const media = await this.finalizeMultipartUploadData({
        variables: videoFinalizationMultiPartInput,
      });

      this.media = media;
      this.onComplete(media);
    }
  }

  /**
   *
   * @param chunk
   * @param part
   * @param sendChunkStarted
   * @returns
   */
  private async sendChunk(
    chunk: Blob,
    part: GetSignedUrlPart,
    sendChunkStarted: () => Promise<void>
  ) {
    const status = await this.upload(chunk, part, sendChunkStarted);
    if (status !== 200) {
      throw new Error("Failed chunk upload");
    }

    return;
  }

  /**
   *
   * @param file
   * @param part
   * @param sendChunkStarted
   * @returns
   */
  private upload(
    file: Blob,
    part: GetSignedUrlPart,
    sendChunkStarted: () => Promise<void>
  ) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        const xhr = (this.activeConnections[part.partNumber - 1] =
          new XMLHttpRequest());
        const threadsQuantity = this._getThreadsQuantity();
        const start = Date.now();

        void sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.partNumber - 1
        );

        // Maybe we should remove the timeout by chunk because we are not 100% sure how to calculate
        // this property. We have to keep in mind a lot of things:
        // - Connection Speed for uploads.
        // - Number of threads dispatched.
        // To do this properly we should calculated Timeout AND Number of threads based on previous
        // results of previous chunks. For now, it's a lot.
        xhr.timeout = this._getRequestTimeoutInSecs() * 1000;
        xhr.upload.addEventListener("progress", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("loadend", progressListener);
        xhr.addEventListener("timeout", progressListener);
        // xhr.addEventListener('load', progressListener)
        // xhr.addEventListener('loadstart', progressListener)

        xhr.open("PUT", part.signedUrl);

        xhr.onreadystatechange = async () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            this.retries = 0;
            const ETag = xhr.getResponseHeader("ETag");

            if (ETag) {
              const uploadedPart = {
                partNumber: part.partNumber,
                eTag: ETag.replaceAll('"', ""),
              };

              this.uploadedParts.push(uploadedPart);
              const end = Date.now();
              this.times.push({
                time: end - start,
                part: part.partNumber,
                timeout: xhr.timeout,
                threads: threadsQuantity,
                result: "success",
              });
              await this._evaluateNetwork();
              resolve(xhr.status);
              delete this.activeConnections[part.partNumber - 1];
            }
          }
        };

        xhr.onerror = async (error) => {
          this.logger.error(`PART: ${part.partNumber} - Request Error`, error);
          const end = Date.now();
          this.times.push({
            time: end - start,
            part: part.partNumber,
            timeout: xhr.timeout,
            threads: threadsQuantity,
            result: "error",
          });
          await this._evaluateNetwork();
          reject(error);
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.ontimeout = async () => {
          this.logger.error(`PART: ${part.partNumber} - Request Timeout`);
          const end = Date.now();
          this.times.push({
            time: end - start,
            part: part.partNumber,
            timeout: xhr.timeout,
            threads: threadsQuantity,
            result: "timeout",
          });
          await this._evaluateNetwork();
          reject(new Error("Upload failed due to Timeout."));
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.onabort = () => {
          this.logger.error(`PART: ${part.partNumber} - Request Aborted`);
          reject(new Error("Upload canceled by User."));
          delete this.activeConnections[part.partNumber - 1];
        };

        this.logger.debug(`PART: ${part.partNumber} - Uploading part...`);
        xhr.send(file);
      }
    });
  }

  /**
   *
   * @param partNumber
   * @param event
   */
  private handleProgress(
    partNumber: number,
    event: ProgressEvent<XMLHttpRequestEventTarget>
  ) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[partNumber] = event.loaded;
      }

      if (event.type === "loadend") {
        this.uploadedSize += this.progressCache[partNumber] || 0;
        delete this.progressCache[partNumber];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      // this.logger.debug(
      //   `PART: ${partNumber} - Progress: ${percentage}% - Event: ${event.type}`
      // )
      this.onProgress({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  /**
   *
   * @returns
   */
  private async _evaluateNetwork() {
    let networkStatus: "low" | "normal" | "high" = this.networkStatus;
    const currentThreadsQuantity = this._getThreadsQuantity();
    const lastTimes = this.times.slice(currentThreadsQuantity * -1);

    const isTimedOut =
      lastTimes.findIndex((time) => time.result === "timeout") !== -1;
    if (isTimedOut) {
      this.logger.debug(
        `There is a time out in the last ${currentThreadsQuantity} requests - Setting NetworkStatus in Low`
      );
      networkStatus = "low";
    } else {
      const isError =
        lastTimes.findIndex((time) => time.result === "error") !== -1;
      if (isError) {
        this.logger.debug(
          `There is an error in the last ${currentThreadsQuantity} requests - Setting NetworkStatus in Low`
        );
        networkStatus = "low";
      } else {
        const avgTime =
          lastTimes.reduce((memo, lastTime) => {
            return memo + lastTime.time;
          }, 0) /
            lastTimes.length +
          1;

        this.logger.debug(
          `AverageTime of the lasts ${currentThreadsQuantity} requests: ${
            avgTime / 1000
          } secs`
        );

        if (avgTime < 12000) {
          if (networkStatus === "low") {
            networkStatus = "normal";
          } else if (networkStatus === "normal") {
            networkStatus = lastTimes.length < 3 ? "normal" : "high";
          } else {
            networkStatus = "high";
          }
        } else if (avgTime < 45000) {
          networkStatus = "normal";
        } else {
          networkStatus = "low";
        }
      }
    }

    this.logger.debug(JSON.parse(JSON.stringify(this.times)));
    if (this.networkStatus === networkStatus) {
      this.logger.debug(`Keep the same NetworkStatus = ${networkStatus}`);
    } else {
      this.logger.debug(
        `Old NetworkStatus = ${this.networkStatus} - New NetworkStatus = ${networkStatus}`
      );
      this.networkStatus = networkStatus;
    }

    return;
  }

  /**
   * This must be bigger than or equal to 5MB,
   * otherwise AWS will respond with:
   * "Your proposed upload is smaller than the minimum allowed size"
   *
   * @returns
   */
  private _getChunkSize() {
    return CHUNK_SIZE;
  }

  /**
   *
   * @param fileType
   * @returns
   */
  private _getMimeTypeByFileType(fileType: string) {
    return fileType === "video/quicktime" ? "video/mp4" : fileType;
  }

  /**
   *
   * @returns
   */
  private _getRetryIntervalInSecs() {
    return RETRY_INTERVAL;
  }

  /**
   *
   * @returns
   */
  private _getThreadsQuantity() {
    if (this.networkStatus === "high") {
      return THREADS_QUANTITY;
    } else if (this.networkStatus === "normal") {
      return 3;
    } else {
      return 1;
    }
  }

  /**
   *
   * @returns
   */
  private _getMaxRetriesPerThread() {
    return MAX_RETRIES_PER_THREAD;
  }

  /**
   *
   * @returns
   */
  private _getMaxRetries() {
    return Math.min(
      this._getThreadsQuantity() * this._getMaxRetriesPerThread(),
      MAX_RETRIES
    );
  }

  /**
   *
   * @returns
   */
  private _getRequestTimeoutInSecs() {
    if (this.networkStatus === "high") {
      return REQUEST_TIMEOUT;
    } else if (this.networkStatus === "normal") {
      return 45;
    } else {
      return 0;
    }
  }
}

/** Signature of a logging function */
interface LogFn {
  (message?: unknown, ...optionalParams: unknown[]): void;
}

/** Basic logger interface */
interface Logger {
  debug: LogFn;
  log: LogFn;
  warn: LogFn;
  error: LogFn;
}

/** Log levels */
type LogLevel = "debug" | "log" | "warn" | "error";

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
const NO_OP: LogFn = (message?: unknown, ...optionalParams: unknown[]) => {};

/** Logger which outputs to the browser console */
class ConsoleLogger implements Logger {
  readonly debug: LogFn;
  readonly log: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  private static getLogPrefix = () => {
    return (
      "[" +
      new Date().getFullYear() +
      "/" +
      (new Date().getMonth() + 1) +
      "/" +
      new Date().getDate() +
      " " +
      new Date().getHours() +
      ":" +
      new Date().getMinutes() +
      ":" +
      new Date().getSeconds() +
      "][S3Uploader]:"
    );
  };

  constructor(options?: { level?: LogLevel }) {
    const { level } = options || {};

    const log = console.log;
    const newLog = function () {
      // 1. Convert args to a normal array
      // eslint-disable-next-line prefer-rest-params
      const args = Array.from(arguments);
      // OR you can use: Array.prototype.slice.call( arguments );

      // 2. Prepend log prefix log string
      args.unshift(ConsoleLogger.getLogPrefix());

      // 3. Pass along arguments to console.log
      log.apply(console, args);
    };

    const debug = console.debug;
    const newDebug = function () {
      // eslint-disable-next-line prefer-rest-params
      const args = Array.from(arguments);
      args.unshift(ConsoleLogger.getLogPrefix());
      debug.apply(console, args);
    };

    const warn = console.warn;
    const newWarn = function () {
      // eslint-disable-next-line prefer-rest-params
      const args = Array.from(arguments);
      args.unshift(ConsoleLogger.getLogPrefix());
      warn.apply(console, args);
    };

    const error = console.error;
    const newError = function () {
      // eslint-disable-next-line prefer-rest-params
      const args = Array.from(arguments);
      args.unshift(ConsoleLogger.getLogPrefix());
      error.apply(console, args);
    };

    this.error = newError.bind(console);
    if (level === "error") {
      this.warn = NO_OP;
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.warn = newWarn.bind(console);
    if (level === "warn") {
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.log = newLog.bind(console);
    if (level === "log") {
      this.debug = NO_OP;

      return;
    }

    this.debug = newDebug.bind(console);
  }
}
