import { styled } from "@mui/material/styles";

export const LeftButtonWrapper = styled("div")`
  margin-left: -10px;

  /* <Link> between <ButtonWrapper> and <RoundButton> */
  & > a {
    display: inline-block;
  }
`;

export const RightButtonWrapper = styled("div")`
  margin-right: -10px;
`;

export const RoundButton = styled("button")`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  appearance: none;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
  background-color: white;
  :hover {
    cursor: pointer;
    box-shadow: 0px 2px 2px
        ${({ theme }) => theme.kinetic.v2.palette.rgba.grayTextDark(0.16)},
      0px 2px 8px
        ${({ theme }) => theme.kinetic.v2.palette.rgba.grayTextDark(0.24)};
  }
  :focus {
    border: ${({ theme }) =>
      `2px solid ${theme.kinetic.v2.palette.rgb.fluoro}`};
  }
`;

RoundButton.defaultProps = {
  type: "button",
};
