import { css, styled } from "@mui/material/styles";
import { Grid } from "../../Grid";
import { RoundButton } from "../../RoundButton";
import { Body1 } from "../../BSPBody1";
import { Body2 } from "../../BSPBody2";
import { withTransientProps } from "../../../../lib/emotion";

export const Wrapper = styled(Grid)`
  min-height: 64px;
  align-items: center;
  justify-content: space-between !important;
`;

type DialogTitleItemProps = { $isCenter: boolean; $hasCloseButton: boolean };

export const LeftSpaceHolder = styled("div")`
  width: 50px;
`;
export const DialogTitleItem = styled(
  Grid,
  withTransientProps
)<DialogTitleItemProps>`
  padding: ${({ theme }) => theme.kinetic.paddings[20]};
  ${({ $isCenter }) =>
    $isCenter &&
    css`
      text-align: center;
    `}
`;

export const DialogTitle = styled("h1")`
  ${Body1}
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

export const DialogSubtitle = styled("h2")`
  ${Body2}
  margin: 0;
`;

export const DialogCloseButton = styled(RoundButton)`
  margin-right: 10px;
`;
