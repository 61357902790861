import { DialogContentProps } from "@mui/material/DialogContent";
import { DialogContent as StyledDialogContent } from "./styles";
import React from "react";

export type Props = DialogContentProps &
  React.PropsWithChildren<{ noPadding?: boolean }>;

export function DialogContent({
  dividers = true,
  noPadding = false,
  ...rest
}: Props) {
  return (
    <StyledDialogContent dividers={dividers} $noPadding={noPadding} {...rest} />
  );
}
