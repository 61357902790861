import {
  MAX_FILE_SIZE,
  MAX_PHOTO_FILE_SIZE,
  MAX_VIDEO_FILE_SIZE,
  MAX_VTT_FILE_SIZE,
  FILE_ERROR_MESSAGE,
  PHOTO_ERROR_MESSAGE,
  VIDEO_ERROR_MESSAGE,
  VTT_SRT_ERROR_MESSAGE,
} from "./src/lib/uploads";

export { useSegment } from "./src/app/segment";

export { debounce } from "./src/utils/debounce";
export { getTraits } from "./src/utils/traits";
export {
  uploadToCloudinary,
  getMimeType,
} from "./src/utils/uploadToCloudinary";
export { UploaderS3 } from "./src/utils/uploadToS3";

export {
  langmap,
  getLanguageAndRegion,
  getIsoLanguage,
  getLanguageFullName,
} from "./src/lib/langmap";
export { useNotification } from "./src/app/core/Notification";

export const uploadConfig = {
  MAX_FILE_SIZE,
  MAX_PHOTO_FILE_SIZE,
  MAX_VIDEO_FILE_SIZE,
  MAX_VTT_FILE_SIZE,
  FILE_ERROR_MESSAGE,
  PHOTO_ERROR_MESSAGE,
  VIDEO_ERROR_MESSAGE,
  VTT_SRT_ERROR_MESSAGE,
};
