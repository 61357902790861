import { css, styled, keyframes } from "@mui/material/styles";
import { withTransientProps } from "../../../lib/emotion";

type DiameterProps = { $diameter: number };

const sized = ({ $diameter }: DiameterProps) => css`
  width: ${$diameter}px;
  height: ${$diameter}px;
`;

export const Root = styled("div", withTransientProps)<DiameterProps>`
  ${sized}
  display: inline-block;
`;

export type ProgressColorProp = "black" | "white" | "blue" | "red" | "theme";

type SpinnerProps = DiameterProps & {
  $color: ProgressColorProp;
  $donutPathId: string;
};

export const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const twoStepGradient = (step1: string, step2: string) => css`
  background: ${step2};
  background: conic-gradient(
    ${step1} 0deg,
    ${step1} 45deg,
    ${step2} 316.872deg,
    ${step2} 360deg
  );
`;

const threeStepGradient = (step1: string, step2: string, step3: string) => css`
  background: ${step3};
  background: conic-gradient(${step1} 0deg, ${step2} 180deg, ${step3} 360deg);
`;

export const Spinner = styled("div", withTransientProps)<SpinnerProps>`
  ${sized};
  border-radius: 50%;
  animation: ${rotate} 1.4s linear infinite;
  clip-path: url(#${({ $donutPathId }) => $donutPathId});
  ${({ theme, $color }) => {
    const { rgb, rgba } = theme.kinetic.v2.palette;

    switch ($color) {
      // Leave theme decide which color to use base of the parameter passed here
      // TODO: When we have decided which colors to use we update here, right now is black/white
      case "theme":
        return twoStepGradient(
          rgba.grayTextDark(0),
          theme.colors.typography[3]
        );
      case "black":
        return twoStepGradient(rgba.white(0), rgb.grayTextDark);
      case "white":
        return twoStepGradient(rgba.grayTextDark(0), rgb.white);
      case "blue":
      default:
        return threeStepGradient(
          "rgba(235, 252, 252, 0)",
          rgb.fluoro,
          rgb.blue
        );
      case "red":
        return twoStepGradient(rgba.white(0), rgb.negative);
    }
  }};
`;

export const HiddenSvg = styled("svg")`
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
`;
