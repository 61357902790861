"use client";

import React, {
  createContext,
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
  ReactNode,
  PropsWithChildren,
} from "react";
import {
  OptionsObject,
  SnackbarMessage,
  SnackbarOrigin,
  SnackbarProvider,
  SnackbarProviderProps,
  useSnackbar,
  VariantType,
} from "notistack";
import { CloseIcon } from "../../../../icons";
import { Dialog, DialogType as DialogTypeEnum } from "./components/Dialog";
import { DeleteDialogCustomProps } from "./components/Dialog/DeleteDialog";
import { ConfirmDialogCustomProps } from "./components/Dialog/ConfirmDialog";

type Props = Omit<SnackbarProviderProps, "anchorOrigin" | "action"> & {
  position: Partial<SnackbarOrigin>;
};

interface DialogOptionsMap {
  confirm: ConfirmDialogCustomProps;
  delete: DeleteDialogCustomProps;
}

type DialogType = "confirm" | "delete";

type DialogState = {
  show: boolean;
  type: DialogTypeEnum;
  message?: ReactNode;
  customProps?: DialogOptionsMap[DialogType];
  onCancel: () => void;
  onSubmit: (dontShowAgain: boolean) => void;
};

type IContext = {
  showDialog: <T extends DialogType = "confirm">(
    message: ReactNode,
    type?: T,
    options?: DialogOptionsMap[T]
  ) => Promise<boolean>;
};

const DIALOG_DEFAULT_STATE = {
  show: false,
  type: DialogTypeEnum.CONFIRM,
  message: undefined,
  customProps: undefined,
  onCancel: () => ({}),
  onSubmit: () => ({}),
};

const Context = createContext<IContext>({
  showDialog: async () => false,
});

export function Notification({
  children,
  position,
  ...props
}: PropsWithChildren<Props>) {
  const providerRef = createRef<SnackbarProvider>();
  const timeoutRef = useRef<number | undefined>(undefined);

  const [dialog, setDialog] = useState<DialogState>(DIALOG_DEFAULT_STATE);

  useEffect(() => {
    // Clear previous timeout is dialog is opened before it finished
    if (dialog.show) {
      window.clearTimeout(timeoutRef.current);
    }
  }, [dialog.show]);

  const clearDialogData = (after: number) => {
    timeoutRef.current = window.setTimeout(() => {
      setDialog(DIALOG_DEFAULT_STATE);

      window.clearTimeout(timeoutRef.current);
    }, after);
  };

  const showDialog = <T extends DialogType>(
    message: ReactNode,
    type?: DialogType,
    options?: DialogOptionsMap[T]
  ) => {
    return new Promise<boolean>((resolve) => {
      const onCancel = () => {
        setDialog((prevState) => ({ ...prevState, show: false }));

        resolve(false);

        clearDialogData(2000);
      };

      const onSubmit = (dontShowAgain: boolean) => {
        setDialog((prevState) => ({ ...prevState, show: false }));

        resolve(true);

        clearDialogData(2000);

        if (dontShowAgain && !!options?.dontShowAgainCheckbox) {
          window.sessionStorage.setItem(
            `dont-show-again-${options.dontShowAgainCheckbox}`,
            "true"
          );
        }
      };

      if (
        !!options?.dontShowAgainCheckbox &&
        window.sessionStorage.getItem(
          `dont-show-again-${options.dontShowAgainCheckbox}`
        )
      ) {
        return resolve(true);
      }

      // Cast string to DialogTypeEnum
      let enumType: DialogTypeEnum = DialogTypeEnum.CONFIRM;
      if (type) {
        switch (type) {
          case "delete":
            enumType = DialogTypeEnum.DELETE;
            break;
          case "confirm":
            enumType = DialogTypeEnum.CONFIRM;
            break;
        }
      }

      setDialog((prevState) => ({
        show: true,
        type: enumType ?? prevState.type,
        message,
        customProps: options,
        onCancel,
        onSubmit,
      }));
    });
  };

  return (
    <SnackbarProvider
      ref={providerRef}
      maxSnack={2}
      {...props}
      anchorOrigin={{
        vertical: position.vertical || "bottom",
        horizontal: position.horizontal || "left",
      }}
      action={(key) => (
        <CloseIcon onClick={() => providerRef.current?.closeSnackbar(key)} />
      )}
      classes={{
        root: "SnackbarItem-nowrap",
      }}
    >
      <Context.Provider
        value={{
          showDialog,
        }}
      >
        <Dialog
          as={dialog.type}
          show={dialog.show}
          customProps={dialog.customProps}
          onCancel={dialog.onCancel}
          onSubmit={dialog.onSubmit}
        >
          {dialog.message}
        </Dialog>
        {children}
      </Context.Provider>
    </SnackbarProvider>
  );
}

export function useNotification() {
  const { enqueueSnackbar: notify, closeSnackbar: close } = useSnackbar();

  const { showDialog } = useContext(Context);

  // TODO: Expose all the props in the future. This will require a change in every "notify" call.
  return {
    ask: showDialog,
    notify: (
      message: SnackbarMessage,
      variant: VariantType,
      options: OptionsObject = {}
    ) => notify(message, { ...options, variant }),
    close,
  };
}
