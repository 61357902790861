import { css, styled } from "@mui/material/styles";
import InputLabelMui from "@mui/material/InputLabel";
import { Body2 } from "../BSPBody2";

export const ButtonLabel = css`
  font-family: "Roboto", var(--bigspring-primary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0125em;
`;

export const BSPButtonLabel = styled("span")`
  ${ButtonLabel};
`;

export const BSPInputLabel = styled(InputLabelMui)`
  ${Body2}
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;
